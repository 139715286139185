import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { theme_combinations, font_combinations } from '../../../global_store/theme_combinations';
import BlogCard from './blogCard';
import { Link, Route, Routes } from 'react-router-dom';
import Blog from './Blog';


const BlogCardsContainer = memo(function BlogCardsContainer(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    let { BG, is_mobile1, is_mobile3, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure,
        styles, blog_cards, search } = props

    const condition = (el) => {
        if (JSON.parse(el?.b_headings) && JSON.parse(el?.b_headings)?.b_title && JSON.parse(el?.b_headings)?.b_title.toLowerCase().indexOf(search.toLowerCase()) != -1) {
            return true
        }
        return false
    }

    return (
        <div style={{ ...styles.flex_common2, width: "100%", }}>
            {
                !is_mobile1 &&
                <div style={{ ...styles.flex_common5, width: "100%", }}> {/* PC */}
                    {blog_cards.map((el, id) => {
                        if (condition(el)) {
                            return <Link key={id}
                                to={`/blog/${el?.b_url}`}
                                state={{ b_url: el.b_url }} // can be picked by using useLocation in next routes
                                style={{ ...styles.flex_common4, width: "100%", textDecoration: "none", }}
                            ><BlogCard key={id} idd={id} ts_created={el.ts_created} ell={JSON.parse(el?.b_headings)} {...props} />
                            </Link>
                        }
                    }
                    )}
                </div>}

            {is_mobile1 &&
                <div style={{ ...styles.flex_common5, width: "100%", }}> {/* PC */}
                    {blog_cards.map((el, id) => {
                        if (condition(el)) {
                            return <Link key={id}
                                to={`/blog/${el?.b_url}`}
                                state={{ b_url: el.b_url }} // can be picked by using useLocation in next routes
                                style={{ ...styles.flex_common4, width: "100%", textDecoration: "none", }}
                            ><BlogCard key={id} idd={id} ts_created={el.ts_created} ell={JSON.parse(el?.b_headings)} {...props} />
                            </Link>
                        }
                    }
                    )}
                </div>}
        </div >
    )
})

export default BlogCardsContainer
