import React, { memo } from 'react';
import { theme_combinations, font_combinations, blog_colors_combination } from '../../../global_store/theme_combinations';
import { useParams, useLocation } from 'react-router-dom';
import { base_url } from '../../../config/env';
import axios from 'axios';
import { ActionSetAmp } from '../../../global_store/amp_reducer';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CodeSection } from "react-code-section-lib"
import { getDatefromTs } from '../../../Utils/dateRelated/dateUtils';


const Blog = memo(function Blog(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    let { BG, is_mobile1, is_mobile3, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure, styles,
        blog_cards, idd, setBlogVisibility } = props

    const bg = n_mode ? blog_colors_combination.blog_card_bg_night : blog_colors_combination.blog_card_bg_day
    const txt_clr = n_mode ? blog_colors_combination.blog_text_color_night : blog_colors_combination.blog_text_color_day

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const getParamsUrl = useParams()
    const getSentParams = useLocation()
    // console.log("params : ", getParamsUrl.blogUrl, "\neahfejf", getSentParams);
    React.useEffect(() => {
        setBlogVisibility(true)
        getBlog()
    }, [])

    const [blog, setBlog] = React.useState({})
    const getBlog = () => {
        const url = `${base_url}/blog/${getParamsUrl?.blogUrl}`
        axios.get(url,).then((res) => {
            if (res.status == 200) {
                setBlog(res.data)
            } else {
                dispatch(ActionSetAmp({ amp: 0 }))
                localStorage.setItem("token", "")
            }
        }

        ).catch((err) => {
            console.log({ err });
            dispatch(ActionSetAmp({ amp: 0 }))
            localStorage.setItem("token", "")
        })
    }
    const b_headingss = blog?.b_headings ? JSON.parse(blog?.b_headings) : undefined
    const b_contentss = blog?.b_contents ? JSON.parse(blog?.b_contents) : undefined
    const measures = !is_mobile3 ? measure * 1 : measure * 1
    const bullets_size_scale = measure * 1.6
    const color = n_mode ? blog_colors_combination.blog_text_color_night : blog_colors_combination.blog_text_color_day
    return (
        <div id="Blog" style={{ width: "100%" }}>
            {!is_mobile1 &&
                <div style={{ ...styles.flex_common5, marginTop: "12vh", width: "100%", minHeight: "50vh" }}> {/* PC */}
                    {Object.keys(blog).length ? <div style={{ width: "60%", minWidth: "780px", marginBottom: `${measures * 20}vh` }} >
                        <div>
                            <div style={{ color: color, fontSize: `${measures * 5.5}vh`, fontWeight: "bold" }}>
                                {b_headingss?.b_title}
                            </div>
                            <div style={{ marginTop: `${measures * 2}vh`, color: color, fontSize: `${measures * 2}vh`, }}>
                                {b_headingss?.b_topix}
                            </div>
                            <div style={{
                                marginTop: `${measures * 2}vh`, color: color, fontSize: `${measures * 2}vh`,
                                color: blog_colors_combination.blog_details_time, paddingBottom: `${measures * 2}vh`,
                                borderBottom: `1px solid ${blog_colors_combination.blog_details_time}`,
                            }}>
                                {`Last edited at ` + getDatefromTs(blog?.ts_updated, 'DD MMM YYYY, IST HH:mm:ss A') + ` by Vishal Maurya`}
                            </div>
                        </div>
                        <div style={{ marginTop: `${measures * 3}vh` }}>
                            {b_contentss && b_contentss.map((el, id) => {
                                if (el[id].content_type == "Heading" && el[id].value) {
                                    return <div key={id} style={{ marginTop: `${measures * 5}vh`, color: color, fontSize: `${measures * 5}vh`, fontWeight: "bold" }}>
                                        {el[id].value}
                                    </div>
                                }
                                else if (el[id].content_type == "Line" && el[id].value) {
                                    return <div key={id} style={{ marginTop: `${measures * 2}vh`, color: color, fontSize: `${measures * 2}vh`, }}>
                                        {el[id].value}
                                    </div>
                                }
                                else if (el[id].content_type == "Bullet" && el[id].value) {
                                    return <div key={id} style={{
                                        ...styles.flex_common2, justifyContent: "flex-start", width: "100%", marginTop: `${measures * 2}vh`,
                                        color: color, fontSize: `${measures * 2}vh`,
                                    }}>
                                        <div style={{ height: `${measures * bullets_size_scale}vh`, width: `${measures * bullets_size_scale}vh`, background: color, borderRadius: `${measures * bullets_size_scale / 2}vh`, marginRight: `${measures * 1}vw` }}></div>
                                        <div>
                                            {el[id].value}
                                        </div>
                                    </div>
                                }
                                else if (el[id].content_type == "Code" && el[id].value) {
                                    return <div key={id} style={{
                                        color: color, fontSize: `${measures * 2}vh`,
                                    }}>
                                        <CodeSection lang={(el[id]?.value && el[id].value?.code_lang) ? el[id].value?.code_lang : ""} style={{ margin: 0 }} theme={n_mode ? "light" : "dark"}>
                                            {/* 'javascript','', 'react', 'angular', 'vue', 'python', */}
                                            {(el[id]?.value && el[id].value?.code) ? el[id].value?.code : ""}
                                        </CodeSection>
                                    </div>
                                }
                                else if (el[id].content_type == "Small_heading" && el[id].value) {
                                    return <div key={id} style={{ marginTop: `${measures * 3}vh`, color: color, fontSize: `${measures * 4}vh`, fontWeight: "bold" }}>
                                        {el[id].value}
                                    </div>
                                }
                                else if (el[id].content_type == "Bullet_link" && el[id].value) {
                                    return <div key={id} style={{
                                        ...styles.flex_common2, justifyContent: "flex-start", width: "100%", marginTop: `${measures * 2}vh`,
                                        color: color, fontSize: `${measures * 1.8}vh`,
                                    }}>
                                        <div style={{ height: `${measures * (bullets_size_scale / 4)}vh`, width: `${measures * (bullets_size_scale / 4)}vh`, background: color, borderRadius: `${measures * (bullets_size_scale / 8)}vh`, marginRight: `${measures * 1}vw` }}></div>
                                        <div>
                                            {el[id].value}
                                        </div>
                                    </div>
                                }
                            }

                            )}
                        </div>
                    </div> : ""}
                </div>
            }

            {is_mobile1 &&
                <div style={{ ...styles.flex_common5, marginTop: "12vh", width: "100%", minHeight: "100vh" }}> {/* PC */}
                    {Object.keys(blog).length ? <div style={{ width: "90%", marginBottom: `${measures * 20}vh` }} >
                        <div>
                            <div style={{ color: color, fontSize: `${measures * 5.5}vh`, fontWeight: "bold" }}>
                                {b_headingss?.b_title}
                            </div>
                            <div style={{ marginTop: `${measures * 2}vh`, color: color, fontSize: `${measures * 2}vh`, }}>
                                {b_headingss?.b_topix}
                            </div>
                            <div style={{
                                marginTop: `${measures * 2}vh`, color: color, fontSize: `${measures * 2}vh`,
                                color: blog_colors_combination.blog_details_time, paddingBottom: `${measures * 2}vh`,
                                borderBottom: `1px solid ${blog_colors_combination.blog_details_time}`,
                            }}>
                                {`Last edited at ` + getDatefromTs(blog?.ts_updated, 'DD MMM YYYY, IST HH:mm:ss A') + ` by Vishal Maurya`}
                            </div>
                        </div>
                        <div style={{ marginTop: `${measures * 3}vh` }}>
                            {b_contentss && b_contentss.map((el, id) => {
                                if (el[id].content_type == "Heading" && el[id].value) {
                                    return <div style={{ marginTop: `${measures * 5}vh`, color: color, fontSize: `${measures * 5}vh`, fontWeight: "bold" }}>
                                        {el[id].value}
                                    </div>
                                }
                                else if (el[id].content_type == "Line" && el[id].value) {
                                    return <div style={{ marginTop: `${measures * 2}vh`, color: color, fontSize: `${measures * 2}vh`, }}>
                                        {el[id].value}
                                    </div>
                                }
                                else if (el[id].content_type == "Bullet" && el[id].value) {
                                    return <div style={{
                                        ...styles.flex_common2, justifyContent: "flex-start", width: "100%", marginTop: `${measures * 2}vh`,
                                        color: color, fontSize: `${measures * 2}vh`,
                                    }}>
                                        <div style={{ height: `${measures * bullets_size_scale}vh`, width: `${measures * bullets_size_scale}vh`, background: color, borderRadius: `${measures * bullets_size_scale / 2}vh`, marginRight: `${measures * 1}vw` }}></div>
                                        <div>
                                            {el[id].value}
                                        </div>
                                    </div>
                                }
                                else if (el[id].content_type == "Code" && el[id].value) {
                                    return <div style={{
                                        color: color, fontSize: `${measures * 2}vh`,
                                    }}>
                                        <CodeSection lang={(el[id]?.value && el[id].value?.code_lang) ? el[id].value?.code_lang : ""} style={{ margin: 0 }} theme={n_mode ? "light" : "dark"}>
                                            {/* 'javascript','', 'react', 'angular', 'vue', 'python', */}
                                            {(el[id]?.value && el[id].value?.code) ? el[id].value?.code : ""}
                                        </CodeSection>
                                    </div>
                                }
                                else if (el[id].content_type == "Small_heading" && el[id].value) {
                                    return <div style={{ marginTop: `${measures * 3}vh`, color: color, fontSize: `${measures * 4}vh`, fontWeight: "bold" }}>
                                        {el[id].value}
                                    </div>
                                }
                                else if (el[id].content_type == "Bullet_link" && el[id].value) {
                                    return <div style={{
                                        ...styles.flex_common2, justifyContent: "flex-start", width: "100%", marginTop: `${measures * 2}vh`,
                                        color: color, fontSize: `${measures * 1.8}vh`,
                                    }}>
                                        <div style={{ height: `${measures * (bullets_size_scale / 4)}vh`, width: `${measures * (bullets_size_scale / 4)}vh`, background: color, borderRadius: `${measures * (bullets_size_scale / 8)}vh`, marginRight: `${measures * 1}vw` }}></div>
                                        <div>
                                            {el[id].value}
                                        </div>
                                    </div>
                                }
                            }

                            )}
                        </div>
                    </div> : ""}
                </div>
            }
        </div >

    )
})

export default Blog

const BigHeading = (props) => {
    return <>
        <div style={{}}>BigHeading</div>
    </>
}

const Line = (props) => {
    return <>
        <div style={{}}>Line</div>
    </>
}
