import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { theme_combinations, font_combinations } from '../../../../global_store/theme_combinations';
import { useEffect } from 'react';


const BlogInfo = (props) => {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    const { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    const { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    const { BG, is_mobile1, is_mobile3, text_color, measure, styles, b_headings, setHeadings } = props

    return (<>
        <div style={{ ...styles.flex_common5, width: "100%" }}>
            <div style={{ ...styles.flex_common6, marginTop: `${measure * 5}vh`, width: "100%" }}>
                <h3 style={{ color: text_color }}>Blog date</h3>
                <input type="date" val={b_headings.b_date} style={{ width: "fit-content", height: "3vh", fontSize: "2vh" }}
                    onChange={(e) => { setHeadings({ ...b_headings, b_date: e.target.value }) }} />
            </div>
            <div style={{ ...styles.flex_common6, marginTop: `${measure * 5}vh`, width: "100%" }}>
                <h3 style={{ color: text_color }}>Blog title</h3>
                <input val={b_headings.b_title} placeholder='60 letters' style={{ width: "100%", height: "5vh", fontSize: "3vh" }}
                    onChange={(e) => { setHeadings({ ...b_headings, b_title: e.target.value }) }} />
            </div>
            <div style={{ ...styles.flex_common6, marginTop: `${measure * 5}vh`, width: "100%" }}>
                <h3 style={{ color: text_color, }}>Blog Description</h3>
                <textarea val={b_headings.b_desc} placeholder='150 letters' style={{ resize: "none", width: "100%", height: "10vh", fontSize: "3vh" }}
                    onChange={(e) => { setHeadings({ ...b_headings, b_desc: e.target.value }) }}
                />
            </div>

            <div style={{ ...styles.flex_common6, marginTop: `${measure * 5}vh`, width: "100%" }}>
                <h3 style={{ color: text_color }}>Blog Hot Topics</h3>
                <input value={b_headings.b_topix} placeholder='Enter space seperated topics' style={{ width: "100%", height: "5vh", fontSize: "3vh" }}
                    onChange={(e) => { setHeadings({ ...b_headings, b_topix: e.target.value }) }} />
            </div>
        </div>
    </>
    )
}

export default BlogInfo
