import React, { memo, useState } from 'react';
import { CgMoreVertical } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { TypeAnimation } from 'react-type-animation';
import self_thm1 from '../../assets/images/self_thm1.png';
import self_thm1_second from '../../assets/images/self_thm1_second.png';
import self_thm2 from '../../assets/images/self_thm2.png';
import self_thm3 from '../../assets/images/self_thm3.png';
import self_thm4 from '../../assets/images/self_thm4.png';

import self_thm1_blr from '../../assets/images/self_thm1_blr.jpg';
import self_thm2_blr from '../../assets/images/self_thm2_blr.jpg';
import self_thm3_blr from '../../assets/images/self_thm3_blr.jpg';
import self_thm4_blr from '../../assets/images/self_thm4_blr.jpg';

import { font_combinations, theme_combinations } from '../../global_store/theme_combinations';
import { home_tooltip_messages } from '../model/data';
import './home.css';



const Home = memo(function Hm(props) {
    const [more_info, setMoreInfo] = useState(false)
    // const all_desg = ["Web Developer", "App Developer", "Node Developer", "Programmer"]
    const all_desg = ["a Web Developer", "a Node Developer", "a Programmer", "an App Developer"]

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8, clr10 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]

    let { is_mobile1, text_color, is_tablet1, is_tablet2, is_low_pc1, is_high_pc1, is_mid_high_pc1, is_mobile2, measure } = props
    let fontSize = is_high_pc1 ? "3vw" : is_mid_high_pc1 ? "2.8vw" : is_tablet1 ? "2.8vw" : is_low_pc1 ? "3vw" : "2.8vw"//PC
    let fontweight = n_mode ? "" : 700
    let about_symbol_size = is_high_pc1 ? "1.2vw" : is_mid_high_pc1 ? "1.2vw" : is_tablet1 ? "1.5vw" : is_low_pc1 ? "1.2vw" : "1.2vw"//pc


    let fontSize2 = active_font_comb == "f_comb4" ? "6.5vw" : "8vw" //ph
    let big_font = is_high_pc1 ? "2.5vw" : is_mid_high_pc1 ? "3.5vw" : is_tablet1 ? "5vw" : is_low_pc1 ? "3.5vw" : "4vw"
    let styless = {
        intro: { color: props.text_color, width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", }
    }
    let styles = props.styles


    const handleImage = () => {
        if (props.theme == "thm1") {
            // if (is_mobile1 && n_mode) {
            //     return self_thm1_second
            // }
            return self_thm1
        } else if (props.theme == "thm2") {
            return self_thm2
        } else if (props.theme == "thm3") {
            return self_thm1
            // return self_thm3
        } else {
            return self_thm1
            // return self_thm4
        }
    }
    const handlePlaceholderImage = () => {
        if (props.theme == "thm1") {
            return self_thm1_blr
        } else if (props.theme == "thm2") {
            return self_thm2_blr
        } else if (props.theme == "thm3") {
            return self_thm3_blr
        } else {
            return self_thm4_blr
        }
    }

    return (
        <>
            {!props.is_mobile1 && // PC ONLY
                <div id="Home" style={{}}>
                    <CSSTransition
                        in={props.nav == "top"} timeout={1000}
                        classNames="content"
                        unmountOnExit
                        onExit={() => props.toggleTopNav(true)}
                    >
                        <div className='content'
                            style={{ ...styles.flex_common1, minHeight: "100vh", width: "100vw", }}
                        >
                            <div style={{ ...styles.flex_common2, width: "60%", color: props.text_color, }}>
                                {/* <h1 style={{ fontFamily: font3 }}>Hi, Vishal M. here!</h1> */}



                                {/* ==============================f_comb1 */}
                                {<div style={{
                                    ...styles.flex_common2, flexDirection: "column", justifyContent: "space-between", width: "100%",
                                    height: "70%", display: active_font_comb == "f_comb1" ? "block" : "none"
                                }}>
                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>

                                        <div style={{ ...styless.intro, alignItems: "flex-end", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100vw",
                                                    fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", maxWidth: '40vw' }}>
                                                    <TypeAnimation
                                                        sequence={["Hi! I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb1"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        maxWidth: '60vw',
                                                        fontWeight: fontweight
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[600, "Vishal Maurya"
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{ fontFamily: font_combinations["f_comb1"].intro_name_font_style, color: clr6, fontSize: big_font, wordBreak: "break-word" }}
                                                        />
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>
                                        <div style={{ ...styless.intro, alignItems: "flex-start", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100%",
                                                    fontFamily: font3, fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: '50%' }}>
                                                    <TypeAnimation
                                                        sequence={[1500, "I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb1"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        width: '50%', position: "relative",
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[1800,
                                                                all_desg[0], // Types 'One'
                                                                1000, // Waits 1s
                                                                all_desg[1],// Deletes 'One' and types 'Two'
                                                                1000, // Waits 2s
                                                                all_desg[2], // Types 'Three' without deleting 'Two'
                                                                1000,
                                                                all_desg[3],
                                                                () => {
                                                                    setMoreInfo(true)
                                                                    // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                                                },
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{
                                                                fontFamily: font_combinations["f_comb1"].intro_non_name_font_style,
                                                                wordBreak: "break-word",
                                                            }}
                                                        />
                                                        {more_info &&
                                                            <div style={{ position: "absolute", top: `${measure * 8}vh`, right: "6vw" }}>
                                                                <CgMoreVertical
                                                                    onClick={() => { props.handleNavClickScroll("About") }}
                                                                    size={about_symbol_size} cursor="pointer"
                                                                    title={home_tooltip_messages.about}
                                                                />
                                                            </div>}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}




                                {/* ================================f_comb2 */}
                                {<div style={{
                                    ...styles.flex_common2, flexDirection: "column", justifyContent: "space-between", width: "100%",
                                    height: "70%", display: active_font_comb == "f_comb2" ? "block" : "none"
                                }}>
                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>

                                        <div style={{ ...styless.intro, alignItems: "flex-end", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100vw",
                                                    fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", maxWidth: '40vw' }}>
                                                    <TypeAnimation
                                                        sequence={["Hi! I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb2"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        maxWidth: '60vw',
                                                        fontWeight: fontweight
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[600, "Vishal Maurya"
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{ fontFamily: font_combinations["f_comb2"].intro_name_font_style, color: clr6, fontSize: big_font, wordBreak: "break-word" }}
                                                        />
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>
                                        <div style={{ ...styless.intro, alignItems: "flex-start", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100%",
                                                    fontFamily: font3, fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: '50%' }}>
                                                    <TypeAnimation
                                                        sequence={[1500, "I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb2"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        width: '50%', position: "relative",
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[1800,
                                                                all_desg[0], // Types 'One'
                                                                1000, // Waits 1s
                                                                all_desg[1],// Deletes 'One' and types 'Two'
                                                                1000, // Waits 2s
                                                                all_desg[2], // Types 'Three' without deleting 'Two'
                                                                1000,
                                                                all_desg[3],
                                                                () => {
                                                                    setMoreInfo(true)
                                                                    // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                                                },
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{
                                                                fontFamily: font_combinations["f_comb2"].intro_non_name_font_style,
                                                                wordBreak: "break-word",
                                                            }}
                                                        />
                                                        {more_info &&
                                                            <div style={{ position: "absolute", top: `${measure * 8}vh`, right: "6vw" }}>
                                                                <CgMoreVertical
                                                                    onClick={() => { props.handleNavClickScroll("About") }}
                                                                    size={about_symbol_size} cursor="pointer" />
                                                            </div>}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}




                                {/* ================================ f_comb3 */}
                                {<div style={{
                                    ...styles.flex_common2, flexDirection: "column", justifyContent: "space-between", width: "100%",
                                    height: "70%", display: active_font_comb == "f_comb3" ? "block" : "none"
                                }}>
                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>

                                        <div style={{ ...styless.intro, alignItems: "flex-end", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100vw",
                                                    fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", maxWidth: '40vw' }}>
                                                    <TypeAnimation
                                                        sequence={["Hi! I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb3"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        maxWidth: '60vw',
                                                        fontWeight: fontweight
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[600, "Vishal Maurya"
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{ fontFamily: font_combinations["f_comb3"].intro_name_font_style, color: clr6, fontSize: big_font, wordBreak: "break-word" }}
                                                        />
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>
                                        <div style={{ ...styless.intro, alignItems: "flex-start", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100%",
                                                    fontFamily: font3, fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: '50%' }}>
                                                    <TypeAnimation
                                                        sequence={[1500, "I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb3"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        width: '50%', position: "relative",
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[1800,
                                                                all_desg[0], // Types 'One'
                                                                1000, // Waits 1s
                                                                all_desg[1],// Deletes 'One' and types 'Two'
                                                                1000, // Waits 2s
                                                                all_desg[2], // Types 'Three' without deleting 'Two'
                                                                1000,
                                                                all_desg[3],
                                                                () => {
                                                                    setMoreInfo(true)
                                                                    // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                                                },
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{
                                                                fontFamily: font_combinations["f_comb3"].intro_non_name_font_style,
                                                                wordBreak: "break-word",
                                                            }}
                                                        />
                                                        {more_info &&
                                                            <div style={{ position: "absolute", top: `${measure * 8}vh`, right: "6vw" }}>
                                                                <CgMoreVertical
                                                                    onClick={() => { props.handleNavClickScroll("About") }}
                                                                    size={about_symbol_size} cursor="pointer" />
                                                            </div>}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}




                                {/* ================================ f_comb4 */}
                                {<div style={{
                                    ...styles.flex_common2, flexDirection: "column", justifyContent: "space-between", width: "100%",
                                    height: "70%", display: active_font_comb == "f_comb4" ? "block" : "none"
                                }}>
                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>

                                        <div style={{ ...styless.intro, alignItems: "flex-end", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100vw",
                                                    fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", maxWidth: '40vw' }}>
                                                    <TypeAnimation
                                                        sequence={["Hi! I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb4"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        maxWidth: '60vw',
                                                        fontWeight: fontweight
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[600, "Vishal Maurya"
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{ fontFamily: font_combinations["f_comb4"].intro_name_font_style, color: clr6, fontSize: big_font, wordBreak: "break-word" }}
                                                        />
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "flex-end",
                                        width: "100%", height: "50%",
                                    }}>
                                        <div style={{ ...styless.intro, alignItems: "flex-start", height: "100%", width: "100%", }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common1, color: props.text_color, width: "100%",
                                                    fontFamily: font3, fontSize: fontSize,
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: '50%' }}>
                                                    <TypeAnimation
                                                        sequence={[1500, "I am"]}
                                                        wrapper="div"
                                                        cursor={false}
                                                        repeat={0}
                                                        // repeat={infinity}
                                                        style={{ fontFamily: font_combinations["f_comb4"].intro_non_name_font_style }}
                                                    />
                                                    &nbsp;
                                                </div>
                                                {
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                                                        width: '50%', position: "relative",
                                                    }}>
                                                        <TypeAnimation
                                                            sequence={[1800,
                                                                all_desg[0], // Types 'One'
                                                                1000, // Waits 1s
                                                                all_desg[1],// Deletes 'One' and types 'Two'
                                                                1000, // Waits 2s
                                                                all_desg[2], // Types 'Three' without deleting 'Two'
                                                                1000,
                                                                all_desg[3],
                                                                () => {
                                                                    setMoreInfo(true)
                                                                    // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                                                },
                                                            ]}
                                                            wrapper="div"
                                                            cursor={false}
                                                            repeat={0}
                                                            // repeat={infinity}
                                                            style={{
                                                                fontFamily: font_combinations["f_comb4"].intro_non_name_font_style,
                                                                wordBreak: "break-word",
                                                            }}
                                                        />
                                                        {more_info &&
                                                            <div style={{ position: "absolute", top: `${measure * 8}vh`, right: "6vw" }}>
                                                                <CgMoreVertical
                                                                    onClick={() => { props.handleNavClickScroll("About") }}
                                                                    size={about_symbol_size} cursor="pointer" />
                                                            </div>}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>}





                            </div>
                            {<div style={{ ...styles.flex_common2, width: "40%", }}>
                                <div style={{ ...styles.flex_common2, position: "relative", }}>
                                    <div className={`${props.theme}${(props.theme == "thm1" && !n_mode) ? "_day" : ""}`} style={{ ...styles.flex_common2, }}>
                                        <div className={`${props.theme}${(props.theme == "thm1" && !n_mode) ? "_day" : ""}`}
                                            style={{
                                                ...styles.flex_common2, width: "fit-content", height: "fit-content",
                                            }}>
                                            {/* <div
                                                style={{
                                                    height: `${measure * 30}vw`, width: `${measure * 25}vw`,
                                                    background: (theme == "thm1") ? n_mode ? "#5e5b5b" : text_color : "",
                                                    borderRadius: (theme == "thm1") ? `${measure * 15}vw` : "",
                                                    backgroundImage: `url(${handleImage()})`, backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat", border: "2px solid red",
                                                }}
                                            ></div> */}
                                            <img
                                                style={{
                                                    height: `${measure * 30}vw`,
                                                    objectFit: "contain", background: (theme != "thm2") ? n_mode ? theme == "thm4" ? clr10 : "#5e5b5b" : theme == "thm4" ? clr10 : text_color : "",
                                                    borderRadius: (theme != "thm2") ? `${measure * 15}vw` : ""
                                                }}
                                                src={handleImage()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {/* =========================== FRAME-DESIGN */}
                            {/* {n_mode && <div style={{ ...styles.flex_common2, width: "40%", }}>
                                <div style={{ ...styles.flex_common2, position: "relative" }}>
                                    <div className={`self_imageV_${props.theme}${(props.theme == "thm1" && !n_mode) ? "_day" : ""}`} style={{ ...styles.flex_common2, }}>
                                        <div className={`self_imageH_${props.theme}${(props.theme == "thm1" && !n_mode) ? "_day" : ""}`} style={{ ...styles.flex_common2, width: "fit-content", height: "fit-content" }}>
                                            <img
                                                style={{
                                                    height: "30vw",
                                                    //  height: "60vh",
                                                    objectFit: "contain"
                                                }}
                                                src={handleImage()}>
                                            </img>
                                        </div>
                                    </div>
                                </div>
                            </div>} */}

                        </div>
                    </CSSTransition>


                    {
                        props.nav != "top" &&
                        <div className='content'>
                            <div className='left_c' style={{ color: props.text_color }}><h1 style={{ fontFamily: font3 }}>Hi, Vishal M. here!</h1></div>
                            <div className='right_c'><img className="App-logo" alt="logo" src={handleImage()}></img></div>
                        </div>
                    }
                </div >}


            {
                props.is_mobile1 && // ==========================MOBILE ONLY
                <div id=" " style={{ height: "100vh", }}>

                    <div style={{
                        display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "center",
                        height: "30vh"
                    }}>
                        <div style={{ ...styless.intro, }}>
                            <div style={{
                                fontFamily: regular_text_style, overflow: "hidden", fontSize: fontSize2, whiteSpace: "nowrap",
                            }}>
                                Hi !
                            </div>
                        </div>
                        <div style={{ ...styless.intro, marginTop: "2vh" }}>
                            {/* ============================== f_comb1 & phone */}
                            <div style={{
                                display: active_font_comb == "f_comb1" ? "block" : "none",
                                overflow: "hidden", fontSize: fontSize2, whiteSpace: "nowrap",
                            }}>
                                <TypeAnimation
                                    // className={"name_phone"}
                                    sequence={["I'm Vishal",]}
                                    wrapper="div"
                                    cursor={false}
                                    repeat={0}
                                    // repeat={infinity}
                                    style={{ display: "inline", fontFamily: font_combinations["f_comb1"].font3, color: clr6 }}
                                />
                            </div>

                            {/* ============================== f_comb2 & phone */}
                            <div style={{
                                display: active_font_comb == "f_comb2" ? "block" : "none",
                                overflow: "hidden", fontSize: fontSize2, whiteSpace: "nowrap",
                            }}>
                                <TypeAnimation
                                    // className={"name_phone"}
                                    sequence={["I'm Vishal",]}
                                    wrapper="div"
                                    cursor={false}
                                    repeat={0}
                                    // repeat={infinity}
                                    style={{ display: "inline", fontFamily: font_combinations["f_comb2"].font3, color: clr6 }}
                                />
                            </div>

                            {/* ============================== f_comb3 & phone */}
                            <div style={{
                                display: active_font_comb == "f_comb3" ? "block" : "none",
                                fontFamily: font3, overflow: "hidden", fontSize: fontSize2, whiteSpace: "nowrap",
                            }}>
                                <TypeAnimation
                                    // className={"name_phone"}
                                    sequence={["I'm Vishal",]}
                                    wrapper="div"
                                    cursor={false}
                                    repeat={0}
                                    // repeat={infinity}
                                    style={{ display: "inline", fontFamily: font_combinations["f_comb3"].font3, color: clr6 }}
                                />
                            </div>

                            {/* ============================== f_comb4 & phone */}
                            <div style={{
                                display: active_font_comb == "f_comb4" ? "block" : "none",
                                fontFamily: font3, overflow: "hidden", fontSize: fontSize2, whiteSpace: "nowrap",
                            }}>
                                <TypeAnimation
                                    // className={"name_phone"}
                                    sequence={["I'm Vishal",]}
                                    wrapper="div"
                                    cursor={false}
                                    repeat={0}
                                    // repeat={infinity}
                                    style={{ display: "inline", fontFamily: font_combinations["f_comb4"].font3, color: clr6 }}
                                />
                            </div>


                        </div>
                    </div>


                    {/* {!is_mobile2 && <div style={{ height: "55vh", display: "flex", alignItems: "center", justifyContent: "center", }}>
                        <div style={{ ...styles.flex_common2, position: "relative" }}>
                            <div className={`self_imageV_p_${props.theme}${(props.theme == "thm1" && !n_mode) ? "_day" : ""}`} style={{ ...styles.flex_common2, }}>
                                <div className={`self_imageH_p_${props.theme}${(props.theme == "thm1" && !n_mode) ? "_day" : ""}`} style={{ ...styles.flex_common2, width: "fit-content", height: "fit-content" }}>
                                    <img
                                        style={{ height: "40vh", objectFit: "cover", }}
                                        src={handleImage()}>
                                    </img>
                                </div>
                            </div>
                        </div>
                    </div>} */}

                    {/* {is_mobile2 && <div style={{ height: "55vh", display: "flex", alignItems: "center", justifyContent: "center" }}
                    ><img src={handleImage()}
                        style={{ height: "40vh", objectFit: "cover", }}
                    ></img>
                    </div>} */}

                    {<div style={{ height: "55vh", display: "flex", alignItems: "center", justifyContent: "center" }}
                    ><img src={handleImage()}
                        // placeholderSrc={handlePlaceholderImage()}
                        style={{
                            height: "40vh", objectFit: "cover", background: (theme != "thm2") ? n_mode ? theme == "thm4" ? clr10 : clr6 : theme == "thm4" ? clr10 : text_color : "",
                            borderRadius: (theme != "thm2") ? "20vh" : ""
                        }}
                        key={handleImage()}
                    ></img>
                    </div>}

                    <div style={{}}>
                        {/* ============================== f_comb1 & phone */}
                        <div style={{
                            ...styless.intro, width: "100%", display: active_font_comb == "f_comb1" ? "block" : "none",
                        }}>
                            <div
                                style={{
                                    ...styles.flex_common2,
                                    color: props.text_color, width: "100%", fontSize: fontSize2,
                                }}
                            >
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-end", width: '27%', }}>
                                    <TypeAnimation
                                        sequence={[1000, "I am"]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb1"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />
                                    &nbsp;
                                </div>
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-start", width: '72%', }}>
                                    {<TypeAnimation
                                        sequence={[1500,
                                            all_desg[0], // Types 'One'
                                            2500, // Waits 1s
                                            all_desg[1],// Deletes 'One' and types 'Two'
                                            3500, // Waits 2s
                                            all_desg[2], // Types 'Three' without deleting 'Two'
                                            4500,
                                            all_desg[3],
                                            () => {
                                                // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                            },
                                        ]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb1"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>

                        {/* ============================== f_comb2 & phone */}
                        <div style={{ ...styless.intro, width: "100%", display: active_font_comb == "f_comb2" ? "block" : "none", }}>
                            <div
                                style={{
                                    ...styles.flex_common2,
                                    color: props.text_color, width: "100%", fontSize: fontSize2,
                                }}
                            >
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-end", width: '27%', }}>
                                    <TypeAnimation
                                        sequence={[1000, "I am"]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb2"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />
                                    &nbsp;
                                </div>
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-start", width: '72%', }}>
                                    {<TypeAnimation
                                        sequence={[1500,
                                            all_desg[0], // Types 'One'
                                            2500, // Waits 1s
                                            all_desg[1],// Deletes 'One' and types 'Two'
                                            3500, // Waits 2s
                                            all_desg[2], // Types 'Three' without deleting 'Two'
                                            4500,
                                            all_desg[3],
                                            () => {
                                                // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                            },
                                        ]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb2"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                        {/* ============================== f_comb3 & phone */}
                        <div style={{ ...styless.intro, width: "100%", display: active_font_comb == "f_comb3" ? "block" : "none", }}>
                            <div
                                style={{
                                    ...styles.flex_common2,
                                    color: props.text_color, width: "100%", fontSize: fontSize2,
                                }}
                            >
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-end", width: '27%', }}>
                                    <TypeAnimation
                                        sequence={[1000, "I am"]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb3"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />
                                    &nbsp;
                                </div>
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-start", width: '72%', }}>
                                    {<TypeAnimation
                                        sequence={[1500,
                                            all_desg[0], // Types 'One'
                                            2500, // Waits 1s
                                            all_desg[1],// Deletes 'One' and types 'Two'
                                            3500, // Waits 2s
                                            all_desg[2], // Types 'Three' without deleting 'Two'
                                            4500,
                                            all_desg[3],
                                            () => {
                                                // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                            },
                                        ]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb3"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>
                        {/* ============================== f_comb4 & phone */}
                        <div style={{ ...styless.intro, width: "100%", display: active_font_comb == "f_comb4" ? "block" : "none", }}>
                            <div
                                style={{
                                    ...styles.flex_common2,
                                    color: props.text_color, width: "100%", fontSize: fontSize2,
                                }}
                            >
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-end", width: '27%', }}>
                                    <TypeAnimation
                                        sequence={[1000, "I am"]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb4"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />
                                    &nbsp;
                                </div>
                                <div style={{ ...styles.flex_common2, justifyContent: "flex-start", width: '72%', }}>
                                    {<TypeAnimation
                                        sequence={[1500,
                                            all_desg[0], // Types 'One'
                                            2500, // Waits 1s
                                            all_desg[1],// Deletes 'One' and types 'Two'
                                            3500, // Waits 2s
                                            all_desg[2], // Types 'Three' without deleting 'Two'
                                            4500,
                                            all_desg[3],
                                            () => {
                                                // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                            },
                                        ]}
                                        wrapper="div"
                                        cursor={false}
                                        repeat={0}
                                        // repeat={infinity}
                                        style={{
                                            fontFamily: font_combinations["f_comb4"].intro_non_name_font_style, display: "flex",
                                            alignItems: "center",
                                        }}
                                    />}
                                </div>
                            </div>
                        </div>

                    </div>


                </div>



            }
        </>
    )

});


export default Home;
