import { createSlice } from '@reduxjs/toolkit'

export const AmpReducer = createSlice({
    name: 'amp_change',
    initialState: {
        amp: 0
    },
    reducers: {
        ActionSetAmp: (state, incoming_changes) => {
            let update = incoming_changes?.payload.amp
            return { ...state, amp: update }
        },
    },
})

// Action creators are generated for each case reducer function
export const { ActionSetAmp } = AmpReducer.actions

export default AmpReducer.reducer