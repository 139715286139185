import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {/* =======================================================================  SEO - Optimization  ================ */}
      {/*<Helmet>
        <title>Dev. Vishal</title>
        <link rel="canonical" href="https://www.i.mvishal.site/blog/" />
      </Helmet>*/}
      <Helmet>
        {/*<title>Dev. Vishal</title>*/}
        <title>Dv. Vishal</title>
        {/*<title>How to Integrate Redux Toolkit with React: A Step-by-Step Tutorial</title>*/}
        <link rel="canonical" href="https://www.i.mvishal.site/blog/" />
        <meta name='description' content={"How to Integrate Redux Toolkit with React: A Step-by-Step Tutorial, This guide provides a beginner-friendly introduction to using Redux Toolkit with React \n Redux Toolkit is a popular library for building Redux applications. It provides a set of tools and utilities that can help you reduce boilerplate code and simplify your application logic. In this guide, we will go through the steps of setting up Redux Toolkit and using it to manage state in a simple application."} />
        {/*<meta name='description' content={"fdsfsgvsgsgedgseeerererer"} />*/}
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
