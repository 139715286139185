import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme_combinations, font_combinations } from '../../../global_store/theme_combinations';
import { ActionSetAmp } from '../../../global_store/amp_reducer';
import { useState } from 'react';
import BlogInfo from './blogUnits/BlogHeading';
import BlogEdit from './blogUnits/BlogEdit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
import { errorHandling } from '../../../Utils/API/errorHandling';
import { base_url } from '../../../config/env';


const Amp = memo(function Amp(props) {
    const dispatch = useDispatch()

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    const { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    const { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    const { BG, is_mobile1, is_mobile3, text_color, measure, styles } = props

    const Logout = () => {
        dispatch(ActionSetAmp({ amp: 0 }))
        localStorage.setItem("token", "")
    }


    const amp = useSelector((state) => state.AllReducerCombined.ampReducer.amp)
    const navigate = useNavigate();
    const isValidSession = () => {
        const token = localStorage.getItem("token")
        if (token && amp) {
            const headers = { "auth_token": token, "Content-Type": "application/json", Accept: "application/json" };
            const base_urll = base_url
            const url = `${base_urll}/login/chk`
            axios.get(url, { headers: headers }).then((res) => {
                if (res.status == 200) {
                    dispatch(ActionSetAmp({ amp: 1 }))
                    navigate({ pathname: "/Blog/ampmin2--/dash" })
                } else {
                    dispatch(ActionSetAmp({ amp: 0 }))
                    localStorage.setItem("token", "")

                }
            }

            ).catch((err) => {
                console.log({ err });
                dispatch(ActionSetAmp({ amp: 0 }))
                localStorage.setItem("token", "")
            })
        }
    }
    useEffect(() => {
        isValidSession()
    }, [])



    const [b_headings, setHeadings] = useState({})
    const [b_contents, setContents] = useState([])
    const handleBlogFire = async () => {
        if (validateBlog()) {
            const headers = { "auth_token": localStorage.getItem("token"), "Content-Type": "application/json", Accept: "application/json", };
            const base_urll = base_url
            let url = `${base_urll}/dash/add`
            const body = {
                b_headings: b_headings, b_contents: b_contents
            }
            axios.post(url, body, { headers: headers })
                .then(async (res) => {
                    if (res.status == 200) {
                        setHeadings({});
                        setContents([]);
                    }
                }).catch((err) => {
                    console.log({ err });
                    if (errorHandling(err) == "LOGOUT") {
                        dispatch(ActionSetAmp({ amp: 0 }))
                        localStorage.setItem("token", "")
                    }
                })
        } else {
            alert("Please fill it correct!")
        }
    }

    const validateBlog = () => {
        console.log("b_headings : ", b_headings);
        // let data = {
        //     b_headings: b_headings, b_contents: b_contents
        // }
        // let str = JSON.stringify(b_contents)
        // console.log("JSON.stringify(b_contents) : ", str)
        // let arr = JSON.parse(str)
        // console.log("JSON.parse(str) :", arr)
        // console.log("normal b_contents : ", b_contents);
        if (b_headings.b_date && b_headings.b_title && b_headings.b_topix && b_headings.b_desc) {
            if (b_contents.length > 4) {
                return true
            }
            return false
        }
        return false
    }



    return (
        <div style={{ width: "100%", }}>
            {!is_mobile1 &&                  ////////////  PC 
                <div style={{ ...styles.flex_common5, marginTop: `${measure * 10}vh`, width: "100%", }}>
                    <div style={{ ...styles.flex_common5, width: "60%", minWidth: "780px", }}>

                        <div style={{ ...styles.flex_common5, width: "100%", }}>
                            <div style={{ ...styles.flex_common2, width: "100%", justifyContent: "space-between" }}>
                                <button style={{ fontSize: "2vh", background: clr2, padding: "1vh", borderRadius: "1vh", outline: 0, border: 0, fontWeight: "bold", color: "#fff", cursor: "pointer" }}
                                    onClick={handleBlogFire}  >Submit</button>
                                <button onClick={Logout}  >Logout</button>
                            </div>
                            <BlogInfo
                                BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                b_headings={b_headings} setHeadings={setHeadings}
                            />
                        </div>

                        <BlogEdit
                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                            b_contents={b_contents} setContents={setContents}
                        />
                    </div>
                </div>}

            {is_mobile1 &&
                <div style={{ ...styles.flex_common5, marginTop: `${measure * 10}vh`, width: "100%", }}>
                    <div style={{ ...styles.flex_common5, width: "90%" }}>

                        <div style={{ ...styles.flex_common5, width: "100%", }}>
                            <div style={{ ...styles.flex_common2, width: "100%", justifyContent: "space-between" }}>
                                <button style={{ fontSize: "2vh", background: clr2, padding: "1vh", borderRadius: "1vh", outline: 0, border: 0, fontWeight: "bold", color: "#fff", cursor: "pointer" }}
                                    onClick={handleBlogFire}  >Submit</button>
                                <button onClick={Logout}  >Logout</button>
                            </div>
                            <BlogInfo
                                BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                b_headings={b_headings} setHeadings={setHeadings}
                            />
                        </div>

                        <BlogEdit
                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                            b_contents={b_contents} setContents={setContents}
                        />
                    </div>
                </div>
            }
        </div>

    )
})

export default Amp
