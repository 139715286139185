export const theme_combinations = {
    thm1: {
        bg_day: "whitesmoke",
        // bg_day: "whitesmoke",
        bg_night: "rgb(13, 25, 28)",
        clr1: "aquamarine", // like sky color #7FFFD4
        clr2: "black", // black
        clr3: "aquamarine",
        clr4: "rgb(6, 110, 105)", //#066e69
        clr5: "#fff",
        clr6: "gray",
        clr7: "gray",
        clr8: "rgb(208, 196, 196)",
        clr9: "rgb(87, 87, 87)",
        clr10: "#303030",
    },
    thm3: {
        // bg1: "black",
        bg_day: "whitesmoke",
        bg_night: "#303030",
        clr1: "whitesmoke", //#ff00a6
        clr2: "black",
        clr3: "whitesmoke",
        clr4: "#303030",
        clr5: "#fff",
        clr6: "gray",
        clr7: "gray",
        clr8: "rgb(208, 196, 196)",
        clr9: "rgb(87, 87, 87)",
        clr10: "#303030",
    },
    thm4: {
        // bg1: "white",
        bg_day: "antiquewhite",
        bg_night: "black",
        clr1: "rgb(5, 5, 255)",//#0505ff
        clr2: "black",
        clr3: "rgb(5, 5, 255)",
        clr4: "rgb(5, 5, 255)",
        clr5: "#fff",
        clr6: "gray",
        clr7: "gray",
        clr8: "rgb(208, 196, 196)",
        clr9: "rgb(87, 87, 87)",
        clr10: "#303030",
    },
    thm2: {
        // bg1: "whitesmoke",
        bg_day: "antiquewhite",
        bg_night: "black",
        // bg1: "antiquewhite",
        // bg1:"#fff",
        clr1: "rgb(255, 79, 4)", //#ff4f04
        clr2: "black",
        clr3: "rgb(242, 117, 34)",
        clr4: "rgb(255, 79, 4)",
        clr5: "#fff",
        clr6: "gray",
        clr7: "gray",
        clr8: "rgb(208, 196, 196)",
        clr9: "rgb(87, 87, 87)",
        clr10: "#303030",
    },

    // thm3: {
    //     // bg1: "black",
    //     bg_day: "antiquewhite",
    //     bg_night: "black",
    //     clr1: "rgb(255, 0, 166)", //#ff00a6
    //     clr2: "black",
    //     clr3: "rgb(255, 0, 166)",
    //     clr4: "rgb(255, 0, 166)",
    //     clr5: "#fff",
    //     clr6: "gray",
    //     clr7: "gray",
    //     clr8: "rgb(208, 196, 196)",
    //     clr9: "rgb(87, 87, 87)",
    // },
}

export const font_combinations = {
    "f_comb1": {
        nav_btn_font_style: 'Quicksand',
        regular_text_style: "Quicksand",
        font3: "Quicksand",
        intro_name_font_style: "Quicksand",
        intro_non_name_font_style: "Quicksand",
        card_texts_font_style: "Quicksand",
    },
    "f_comb2": {
        nav_btn_font_style: 'Redressed, cursive',
        regular_text_style: "Redressed, cursive",
        font3: "Croissant One,cursive",
        intro_name_font_style: "'Tangerine', cursive",
        intro_non_name_font_style: "Redressed, cursive",
        card_texts_font_style: "Redressed, cursive",
    },
    // "f_comb3": {
    //     nav_btn_font_style: 'Josefin Sans',
    //     regular_text_style: "Lato, sans-serif",
    //     font3: "Josefin Sans",
    //     intro_name_font_style: "Josefin Sans",
    // },
    "f_comb3": {
        nav_btn_font_style: 'Merriweather',
        regular_text_style: "Merriweather",
        font3: "Merriweather",
        intro_name_font_style: "Merriweather",
        intro_non_name_font_style: "Merriweather",
        card_texts_font_style: "Merriweather",
    },
    "f_comb4": {
        nav_btn_font_style: 'Space Mono',
        regular_text_style: "Space Mono",
        font3: "Merriweather",
        intro_name_font_style: "Merriweather",
        intro_non_name_font_style: "Quicksand",
        card_texts_font_style: "Space Mono",
    },
    // "f_comb4": {
    //     nav_btn_font_style: 'Open Sans',
    //     regular_text_style: "Lora",
    //     font3: "Open Sans",
    //     intro_name_font_style: "Open Sans",
    // },

    // "f_comb4": {
    //     nav_btn_font_style: 'Great Vibes',
    //     regular_text_style: "Great Vibes",
    //     font3: "Great Vibes",
    //     intro_name_font_style: "Great Vibes",
    // },
}

export const blog_colors_combination = {
    blog_day_bg: "whitesmoke",
    blog_night_bg: "#303030",
    blog_text_color_day: "#303030",
    blog_text_color_night: "whitesmoke",
    blog_card_bg_night: "#424242",
    blog_card_bg_day: "#FFFFFF",
    blog_nav_btn_bg_night: "rgba(255, 255, 255, 0.7)",
    blog_nav_btn_bg_day: "gray",
    blog_nav_btn_font_color_night: "rgba(255, 255, 255, 0.7)",
    blog_nav_btn_font_color_day: "gray",
    blog_day_btn_color: "#FFFFFF",
    blog_self_image_bg_day: "gray",
    blog_self_image_bg_night: "gray",
    blog_texts_font_color_day: "black",
    blog_texts_font_color_night: "#FFFFFF",
    blog_details_time: "gray",
    code_bg: "#202124",
    // blog_card_color_night:"#424242",
    // blog_card_color_day:"#424242",


    blogg_fontFamilies: {

    }
}






/* font-family: 'Sofia', cursive; */
/* font-family: 'Tangerine', cursive; */
/* font-family: 'Arizonia', cursive; */
/* font-family: 'Courgette', cursive; */
/* font-family: 'Lato', sans-serif; */
/* font-family: 'Pacifico', cursive; */
/* font-family: 'Redressed', cursive; */
/* font-family: 'Croissant One', cursive; */