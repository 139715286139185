import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { theme_combinations, font_combinations } from '../../../../../global_store/theme_combinations';


const Bullet = memo(function Bullet(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    const { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    const { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    const { BG, is_mobile1, is_mobile3, text_color, measure, styles, setContents, b_contents, content_types, idd, editElement,
        AddNewInBetween,
    } = props



    return (
        <div style={{ ...styles.flex_common6, width: "100%", }}>
            {
                // !is_mobile1 &&
                <div style={{ width: "100%", marginBottom: "7vh" }}> {/* PC */}
                    <div style={{ fontSize: "4vh", color: text_color, fontWeight: "bold", marginBottom: "1vh" }}><div>Bullet</div></div>
                    <textarea
                        value={b_contents[idd][idd].value}
                        placeholder='Enter Bullet'
                        style={{ resize: "none", width: "100%", fontSize: "2vh", height: "20vh" }}
                        onChange={(change) => { editElement(change) }}
                    />
                    <div style={{ ...styles.flex_common2, flexWrap: "wrap", justifyContent: "flex-start", width: "100%", }}>
                        {/* <button style={{ fontSize: "2vh", marginRight: "1vw " }}>Edit</button> */}
                        {["Delete", ...content_types].map((content_type) => {
                            if ((!(idd == (b_contents.length - 1))) || ((idd == (b_contents.length - 1)) && content_type == "Delete")) {
                                return (<button style={{ fontSize: "2vh", cursor: "pointer" }}
                                    onClick={() => { AddNewInBetween(content_type) }}
                                >{content_type == "Delete" ? content_type : `Add ${content_type} below`}</button>)
                            }
                        }
                        )}
                    </div>
                </div>}
        </div>

    )
})

export default Bullet
