import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { theme_combinations, font_combinations, blog_colors_combination } from '../../../global_store/theme_combinations';
import { ActionChangeDayNight, ActionChangeFont, ActionChangeTheme } from '../../../global_store/theme_reducer';
import BlogCardsContainer from './BlogCardsContainer';
import { BsSearch } from 'react-icons/bs'


const BlogIndex = memo(function BlogIndex(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    let { BG, is_mobile1, is_mobile3, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure, styles, setBlogVisibility } = props

    const [search, setSearch] = React.useState("")

    React.useEffect(() => {
        setBlogVisibility(true)
    }, [])

    return (
        <div id="Blog" style={{ width: "100%" }}>
            {
                !is_mobile1 &&
                <div style={{ ...styles.flex_common5, marginTop: "12vh", width: "100%", minHeight: "50vh" }}> {/* PC */}
                    <div style={{ width: "60%", minWidth: "780px", }} >
                        <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%" }}>
                            <div style={{
                                display: "flex", alignItems: "center", width: "100%",
                                color: n_mode ? blog_colors_combination.blog_texts_font_color_night : blog_colors_combination.blog_texts_font_color_day,
                                fontSize: `${measure * 5}vh`, fontWeight: "1000"
                            }}>Vishal's Blog
                            </div>
                            <div style={{
                                ...styles.flex_common2,
                                borderRadius: "1vh",
                                padding: "0.5vh 1vh 0.5vh 1vh",
                                boxShadow: `0 0 0.4vh .2vh ${blog_colors_combination.blog_nav_btn_font_color_day}`
                            }}>
                                <input value={search} onChange={(c) => { setSearch(c.target.value) }}
                                    placeholder='Search by topic'
                                    style={{ outline: 0, border: 0, background: "inherit", fontSize: "2vh", color: text_color }} />
                                <BsSearch color={text_color} size={"2vh"} />
                            </div>
                        </div>
                        <BlogCardsContainer search={search} {...props} />
                    </div>
                </div>}

            {is_mobile1 &&
                <div style={{ ...styles.flex_common5, marginTop: "12vh", width: "100%", marginBottom: "10vh", }}> {/* PC */}
                    <div style={{ width: "90%", }} >
                        <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%" }}>
                            <div style={{
                                display: "flex", alignItems: "center", width: "100%",
                                color: n_mode ? blog_colors_combination.blog_texts_font_color_night : blog_colors_combination.blog_texts_font_color_day,
                                fontSize: `${measure * 5}vh`, fontWeight: "1000"
                            }}>Vishal's Blog</div>

                            <div style={{
                                ...styles.flex_common2,
                                borderRadius: "1vh", width: "30%",
                                padding: "0.5vh 1vh 0.5vh 1vh",
                                boxShadow: `0 0 0.4vh .2vh ${blog_colors_combination.blog_nav_btn_font_color_day}`
                            }}>
                                <input value={search} onChange={(c) => { setSearch(c.target.value) }}
                                    placeholder='Topic'
                                    style={{
                                        outline: 0, border: 0, background: "inherit", fontSize: "2vh",
                                        width: "80%", color: text_color
                                    }} />
                                <BsSearch color={text_color} size={"2vh"} />
                            </div>
                        </div>
                        <BlogCardsContainer search={search} {...props} {...props} />
                    </div>
                </div>
            }
        </div >
    )
})

export default BlogIndex
