

import React, { memo, useLayoutEffect, useState } from 'react';
import { AiOutlineVerticalAlignMiddle } from "react-icons/ai";
import { FaReact } from "react-icons/fa";
import { HiBarsArrowUp, HiOutlineBars3CenterLeft } from "react-icons/hi2";
import { ImDownload } from "react-icons/im";
import { VscFoldDown, VscFoldUp } from "react-icons/vsc";
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import logo_thm_1_day_phone from '../assets/images/logo_thm_1_day_phone.png';
import logo_thm_1_night_phone from '../assets/images/logo_thm_1_night_phone.png';
import logo_thm_2_phone from '../assets/images/logo_thm_2_phone.png';
import logo_thm_3_phone from '../assets/images/when_blog_logo.png';
import logo_thm_4_phone from '../assets/images/logo_thm_4_phone.png';
import when_blog_logo from '../assets/images/when_blog_logo.png';
import { blog_colors_combination, theme_combinations as colors, font_combinations } from '../global_store/theme_combinations';
import './Nav.css';
import { nav_tooltip_messages, resume } from './model/data';
import self_thm1 from '../assets/images/self_thm1.png';
import { IoIosSunny } from 'react-icons/io';
import { MdOutlineDarkMode } from 'react-icons/md';
import { ActionChangeDayNight, ActionChangeFont, ActionChangeTheme } from '../global_store/theme_reducer';

const Nav = memo(function Nav(props) {
    let styles = {
        flex_common1: { display: "flex", justifyContent: "center", },
        flex_common2: { display: "flex", justifyContent: "center", alignItems: "center" }
    }
    const cv = resume

    const { collapseSidebar } = useProSidebar();
    const dispatch = useDispatch()

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6 } = colors[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]

    let { is_tablet1, is_low_pc1, is_high_pc1, is_mid_high_pc1, measure, is_mobile3, is_mobile2, BG, text_color, show_blog, setBlogVisibility } = props
    // let text_color = n_mode ? clr1 : clr4
    let dWidth = window.innerWidth
    let dHeight = window.innerHeight
    let is_mobile1 = props.is_mobile1
    let active_menu = props.active_menu


    let logoo_thm_2 = logo_thm_2_phone
    let logoo_thm_1_day = logo_thm_1_day_phone
    let logoo_thm_1_night = logo_thm_1_night_phone
    let logoo_thm_3 = logo_thm_3_phone
    let logoo_thm_4 = logo_thm_4_phone
    let top_font_size_hover = is_high_pc1 ? "4vh" : is_mobile1 ? "6vw" : is_tablet1 ? "3.5vh" : is_low_pc1 ? "3.5vh" : "3.5vh"
    let top_font_size_no_hover = is_high_pc1 ? "3vh" : is_mobile1 ? "4vw" : is_tablet1 ? "2.5vh" : is_low_pc1 ? "2.5vh" : "2.5vh"
    let borderRadius = "0.7vh" //pc

    let top_icons_size = is_high_pc1 ? "4.5vh" : is_mid_high_pc1 ? "4.5vh" : "4.5vh"

    const handleTopNavButtonsStyle = (id) => {
        let styles = { bg: "", clr: "", brdr: "", fontSize: "", borderRadius: "" }
        styles.bg = active_menu == id ? (!n_mode && (theme == "thm1" || theme == "thm3")) ? clr4 : clr3 : ""
        styles.brdr = `${theme == "thm1" ? "0.3vh" : "0.25vh"} solid ${text_color}`
        styles.clr = active_menu == id ? n_mode ? BG : clr5 : text_color
        const offset = show_blog ? 1 : n_mode ? measure : measure * 1.1
        styles.fontSize = is_high_pc1 ? active_menu == id ? `${3.2 * offset}vh` : `${2.8 * offset}vh` : is_mobile3 ? active_menu == id ? "6vw" : "4vw" : is_tablet1 ? active_menu == id ? "3.2vh" : "2.8vh" : is_low_pc1 ? active_menu == id ? `${3.2 * offset}vh` : `${2.8 * offset}vh` : active_menu == id ? `${3.2 * offset}vh` : `${2.8 * offset}vh`
        styles.borderRadius = "0.8vh"
        return styles
    }
    let sideBar_cv_border = `1px solid ${text_color}`  //pc
    const handleSideNavButtonsStyle = (id) => {
        let styles = { bg: "", clr: "", brdr: "" }
        styles.brdr = props.active_menu == id ? `2px solid ${text_color}` : ""

        return styles
    }


    const decideClassNames = (className) => {
        let final_name = ""
        if (className == "nav1_top") {
            final_name = theme == "thm1" ? 'nav1_top' : "nav1_top2"
        }

        return final_name
    }


    const handleImage = () => {
        if (show_blog) {
            return logoo_thm_3
        } else {

            if (theme == "thm1") {
                return n_mode ? logoo_thm_1_night : logoo_thm_1_day
            } else if (theme == "thm2") {
                return logoo_thm_2
            } else if (theme == "thm3") {
                return logoo_thm_3
            } else if (theme == "thm4") {
                return logoo_thm_4
            }
        }
    }


    const [phone_entry, setPhoneEntry] = useState(false)
    useLayoutEffect(() => {
        setInterval(() => {
            setPhoneEntry(true)
        }, 500);
    }, [])

    const handleDownScroll_m = () => {
        props.handleNavClickScroll(props.pages[props.active_menu + 1], "", false)
    }

    const [alw, setAlw] = useState(false)
    const [attempt, setAttempt] = useState(0)
    const [ts, setTs] = useState(0)
    useLayoutEffect(() => {
        if (attempt > 5) {
            const condtn = (new Date().getTime() * 1 - ts) < 15000
            if (!ts || (ts && (condtn))) {
                setTs(new Date().getTime())
            } else if (!condtn) {
                setAlw(0); setAttempt(0); setTs(0)
            }
        }
        if (attempt == 6) {
            setAlw(true)
            setTimeout(() => { setAlw(0); setAttempt(0); setTs(0) }, 15000)
        }
    }, [attempt])
    const handleAttempt = (el, id) => {
        if (attempt == 0) { if (el == "Skills") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        if (attempt == 1) { if (el == "Skills") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        if (attempt == 2) { if (el == "logoo") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        if (attempt == 3) { if (el == "logoo") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        if (attempt == 4) { if (el == "Work") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        if (attempt == 5) { if (el == "logoo") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 6) { if (el == "Work") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 7) { if (el == "Work") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 8) { if (el == "About") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 9) { if (el == "Work") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 10) { if (el == "About") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 11) { if (el == "logoo") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 12) { if (el == "About") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 13) { if (el == "Skills") { setAttempt(attempt + 1) } else { setAttempt(0) } }
        //if (attempt == 14) { if (el == "Skills") { setAttempt(attempt + 1) } else { setAttempt(0) } }
    }


    return (
        <div className={"nav"}>
            <div className={'nav_img_contn'} >
                {(!props.nav && props.show_triple_bar) && <HiOutlineBars3CenterLeft className='nav_btn' size={"2.5vw"} color={text_color}
                    onClick={() => { props.setNav("left") }}
                />}

            </div>

            {!props.is_mobile1 && // ============================================= FOR PC
                <>
                    {
                        <CSSTransition in={(props.nav == "top")} timeout={1000} classNames="nav_top" unmountOnExit>
                            <div className={'nav_top'} style={{
                                color: clr3, height: "8vh", zIndex: 3,
                                background: BG, transition: `background ${props.bg_transition_duration}ms`,
                            }}>
                                {show_blog && <div className='sign_top' >
                                    <img className='nav_logo'
                                        onClick={() => { props.handleNavClickScroll("Home") }}
                                        style={{
                                            minHeight: props.is_mobile1 ? "30px" : "", position: props.is_mobile1 ? "fixed" : "",
                                            left: props.is_mobile1 ? "2vw" : "", top: props.is_mobile1 ? "2vh" : "", cursor: "pointer"
                                        }} src={when_blog_logo} />
                                </div>}
                                {!show_blog && <div className='sign_top' >
                                    <img className='nav_logo'
                                        onClick={() => { props.handleNavClickScroll("Home"); handleAttempt("logoo") }}
                                        style={{
                                            minHeight: props.is_mobile1 ? "30px" : "", position: props.is_mobile1 ? "fixed" : "",
                                            left: props.is_mobile1 ? "2vw" : "", top: props.is_mobile1 ? "2vh" : "", cursor: "pointer",
                                        }} src={handleImage()} />
                                </div>}
                                <div className='top_nav_right' style={{
                                    ...styles.flex_common2, justifyContent: "flex-end",
                                    fontFamily: regular_text_style, position: props.is_mobile1 ? "fixed" : "",
                                    marginTop: "1.5vh",
                                    left: props.is_mobile1 ? dWidth * 0.45 : "", minWidth: props.is_mobile1 ? dWidth * 0.40 : "",
                                    maxWidth: dWidth * 0.50,
                                }}>
                                    {show_blog
                                        ?  // ===================== PC BLOG NAV
                                        <div className={'nav_top_menus'} style={{
                                            width: "fit-content",
                                            ...styles.flex_common2, justifyContent: "flex-start",
                                            fontFamily: nav_btn_font_style,
                                        }} >
                                            <div style={{ ...styles.flex_common2, marginRight: `${measure * 1}vw` }}>
                                                {n_mode ? <IoIosSunny title={nav_tooltip_messages.open_setting} size={top_icons_size}
                                                    color={blog_colors_combination.blog_day_btn_color} onClick={() => { dispatch(ActionChangeDayNight({ n_mode: !n_mode })) }}

                                                /> :
                                                    <MdOutlineDarkMode title={nav_tooltip_messages.open_setting} size={top_icons_size}
                                                        color={blog_colors_combination.blog_nav_btn_bg_day} onClick={() => { dispatch(ActionChangeDayNight({ n_mode: !n_mode })) }}

                                                    />
                                                }
                                            </div>
                                            {props.blog_menus.map((el, id) =>
                                                <Link to={el !== "Blog" ? "/" : el} key={id} className={"nav1_top"}
                                                    onClick={() => {
                                                        if (el != "Blog") {
                                                            props.setBlogVisibility(false)
                                                        } else {
                                                            props.setBlogVisibility(true)
                                                        }
                                                    }}
                                                    style={{
                                                        ...styles.flex_common2,
                                                        fontSize: handleTopNavButtonsStyle(id).fontSize,
                                                        background: el == "Vishal M" ? "" : n_mode ? blog_colors_combination.blog_nav_btn_bg_night : blog_colors_combination.blog_nav_btn_bg_day,
                                                        transition: `background ${props.bg_transition_duration}ms`,
                                                        color: el != "Vishal M" ? BG : n_mode ? blog_colors_combination.blog_nav_btn_font_color_night : blog_colors_combination.blog_nav_btn_font_color_day,
                                                        borderRadius: handleTopNavButtonsStyle(id).borderRadius,
                                                        padding: `${measure * 0.5}vh ${measure * 1}vh ${measure * 0.5}vh ${measure * 1}vh`,
                                                        marginLeft: el == "Vishal M" ? `${measure * 4}vw` : "",

                                                    }}
                                                >
                                                    <div>{el}</div>
                                                    {el == "Vishal M" &&
                                                        <div style={{ height: `${measure * 5}vh`, marginLeft: `${measure * 1}vw` }}><img src={self_thm1}
                                                            style={{
                                                                height: "100%", objectFit: "contain",
                                                                background: n_mode ? blog_colors_combination.blog_self_image_bg_night : blog_colors_combination.blog_self_image_bg_day,
                                                                borderRadius: `${measure * 2.5}vh`
                                                            }} />
                                                        </div>
                                                    }
                                                </Link>
                                            )}
                                        </div>
                                        :
                                        // ===================== PC NO BLOG NAV
                                        <div className={'nav_top_menus'} style={{ fontFamily: nav_btn_font_style }} >

                                            <div style={{
                                                width: 30, height: "5vh", display: "flex", justifyContent: "center", alignItems: "center",
                                                position: "relative",
                                            }}>
                                                <CSSTransition in={(!props.is_mobile1 && props.show_triple_bar)} timeout={1000} classNames="hide" unmountOnExit>
                                                    <div className='hide'
                                                        style={{
                                                            display: "flex", justifyContent: "center", alignItems: "center", marginRight: "2vw", position: 'absolute',
                                                        }}>
                                                    </div>
                                                </CSSTransition >

                                                <CSSTransition in={!(!props.is_mobile1 && props.show_triple_bar)} timeout={1000} classNames="hide" unmountOnExit>
                                                    <div
                                                        className='hide'
                                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "2vw", position: 'absolute', }}>
                                                        <AiOutlineVerticalAlignMiddle
                                                            title={nav_tooltip_messages.v_align}
                                                            size={`${measure * 3.5}vh`} color={text_color}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => { props.handleNavClickScroll() }}
                                                        />
                                                    </div>
                                                </CSSTransition >
                                            </div>

                                            {props.pages.map((el, id) =>
                                                <Link to={el != "Blog" ? null : el} key={id} className={"nav1_top"}
                                                    onMouseEnter={(e) => { if (id !== props.active_menu) { e.target.style.color = BG; e.target.style.background = ((theme == "thm1" || theme == "thm3") && !n_mode) ? clr4 : clr3; e.target.style.fontSize = "3.3vh" } }}
                                                    onMouseLeave={(e) => { if (id !== props.active_menu) { e.target.style.color = text_color; e.target.style.background = "inherit"; e.target.style.fontSize = "2.5vh" } }}
                                                    onClick={() => {
                                                        handleAttempt(el, id)
                                                        if (el != "Blog") {
                                                            props.handleNavClickScroll(el, id);
                                                            setTimeout(() => { props.setActiveMenu(id); }, 500)
                                                            props.setBlogVisibility(false)
                                                        } else {
                                                            props.setBlogVisibility(true)
                                                        }
                                                    }}
                                                    style={{
                                                        fontSize: handleTopNavButtonsStyle(id).fontSize, background: handleTopNavButtonsStyle(id).bg,
                                                        color: handleTopNavButtonsStyle(id).clr, borderRadius: handleTopNavButtonsStyle(id).borderRadius,
                                                        display: id == 0 ? "none" : "",
                                                        padding: `${measure * 0.5}vh ${measure * 1}vh ${measure * 0.5}vh ${measure * 1}vh`,
                                                        transitionDelay: "0.5s",
                                                    }}
                                                >{el}</Link>
                                            )}
                                            {alw ? <Link to={"/Blog/ampmin2--/login"}
                                                style={{
                                                    textDecoration: "none", fontSize: `${measure * 3.0}vh`, background: BG,
                                                    color: text_color, borderRadius: handleTopNavButtonsStyle().borderRadius,
                                                    padding: `${measure * 0.5}vh ${measure * 1}vh ${measure * 0.5}vh ${measure * 1}vh`,
                                                }}
                                            >Get</Link> : ""}
                                            {<div className={"nav1_top"}
                                                onClick={() => { props.setActiveMenu(4) }}
                                                style={{
                                                    border: handleTopNavButtonsStyle().brdr, fontSize: top_font_size_no_hover, borderRadius: borderRadius,
                                                    padding: `${measure * 0.5}vh ${measure * 1}vh ${measure * 0.5}vh ${measure * 1}vh`
                                                }} >
                                                {< a title={nav_tooltip_messages.download_cv} className='cv' href={cv} target="_blank" style={{ color: text_color }}
                                                >Resume</a>}
                                            </div>}
                                            <div style={{ ...styles.flex_common2 }}>
                                                {<FaReact title={nav_tooltip_messages.open_setting} className='r_icon' size={top_icons_size} color={text_color} onClick={props.handleSetting}
                                                    style={{ marginLeft: "1vw" }}
                                                />}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </CSSTransition >
                    }

                </>
            }

            {/* ================================================SIDE-BAR======================================================== */}
            {< div className={props.nav == "left" ? 'sidebar' : "sidebar_closed"} style={{ background: BG, color: text_color, fontFamily: font3, }
            }>
                <Sidebar className='sidebar_body' style={{ border: 0 }} defaultCollapsed={props.nav != "left"} collapsedWidth={"0px"} transitionDuration={500}>
                    <div className='sidebar_main' style={{ background: BG }}>
                        <div className='bar_upper_portion'>
                            <div className='side_settings' >
                                <HiBarsArrowUp color={text_color} className='arrow_up' size={30}
                                    onClick={() => {
                                        collapseSidebar();
                                        setTimeout(() => { props.setNav("top") }, 501)
                                        setTimeout(() => { props.setNav("top") }, 501)

                                        setTimeout(() => {
                                            props.handleNavClickScroll(`${props.active_menu == 0 ? "/" : `${props.pages[props.active_menu]}`}`)
                                        }, 550);
                                    }}
                                />
                                <div
                                    onClick={props.handleSetting}
                                // onClick={handleThemeChange}
                                >
                                    {!props.side_setting_off && <FaReact color={text_color} size={25} />}
                                </div>
                            </div>
                            <div className='bar_logo_cont'>
                                <img className='bar_logo' src={handleImage()} />
                            </div>
                        </div>
                        <div className='bar_middle_portion'
                        // menuItemStyles={{ button: { '&:hover': { backgroundColor: clr3, color: bg_night, borderRadius: "8px" }, } }}
                        >
                            {props.pages.map((el, id) =>
                                <Link to={el == "" ? "/" : el} key={id} className={theme == "thm1" ? 'side_item' : "side_item2"}
                                    onClick={() => { props.setActiveMenu(id) }}
                                    style={{
                                        border: handleSideNavButtonsStyle(id).brdr,
                                        color: text_color, display: id == 0 ? "none" : ""
                                    }}
                                > {el}
                                </Link>)}
                            <div key={4} className={theme == "thm1" ? 'side_item' : "side_item2"}
                                style={{
                                    border: sideBar_cv_border
                                    // color: props.active_menu == 4 ? bg_night : "", 
                                }}
                            >
                                <a className='cv_side' href={cv} target="_blank" style={{ border: 0 }}
                                // onClick={() => { props.setActiveMenu(4) }}
                                >Resume</a>
                            </div>
                        </div>
                        <div className='bar_bottom_protion' >
                        </div>
                    </div>
                </Sidebar>
            </div >}



            {props.is_mobile1 && //====================================== NAV FOR MOBILE 
                <div className={'phone_nav'} style={{
                    color: clr3, position: "fixed", top: 0, left: 0, height: "7vh", zIndex: 3, width: "100%", minWidth: "250px",
                    background: BG, transition: `background ${props.bg_transition_duration}ms`,
                }}>
                    {!show_blog && !props.is_mobile2 &&
                        <img className='nav_logo'
                            onClick={() => { props.handleNavClickScroll("Home"); handleAttempt("logoo") }}
                            style={{
                                height: `${measure * 4.2}vh`,
                                position: props.is_mobile1 ? "fixed" : "", left: props.is_mobile1 ? "3vw" : "", top: "2vh",
                                cursor: "pointer",
                            }}
                            src={handleImage()} />
                    }
                    {show_blog && !props.is_mobile2 &&
                        <img className='nav_logo'
                            onClick={() => { props.handleNavClickScroll("Home") }}
                            style={{
                                height: `${measure * 4.2}vh`,
                                position: props.is_mobile1 ? "fixed" : "", left: props.is_mobile1 ? "3vw" : "", top: "2vh",
                                cursor: "pointer",
                            }}
                            src={handleImage()} />
                    }
                    {!show_blog ? <a className='cv' href={cv} target="_blank"><ImDownload size={`${measure * 3.1}vh`} color={text_color} style={{
                        position: "fixed", top: "2vh", left: props.is_mobile2 ? dWidth * 0.05 : dWidth * 0.27,

                    }} /></a>
                        : ""}
                    <CSSTransition in={(phone_entry)} timeout={1000} classNames="phone_nav" unmountOnExit>
                        <div
                            style={{
                                display: "flex", justifyContent: "flex-end", alignItems: "center",
                                fontFamily: regular_text_style, position: "fixed",
                                width: is_mobile3 ? show_blog ? dWidth * 0.7 : dWidth * 0.4 : dWidth * 0.57,
                                borderRadius: "12px", minWidth: is_mobile2 ? "50px" : "200px",
                                position: "fixed", top: "1.2vh", right: "1vw",
                            }}>
                            <div
                                style={{
                                    display: "flex", width: "fit-content", width: "100%", justifyContent: "space-between",
                                    alignItems: "center", overflowX: "auto", height: "fit-content", minHeight: "5vh",
                                }}
                            >
                                {!show_blog && props.pages.map((el, id) => {
                                    if (el !== "Contact") {
                                        return (

                                            <Link to={el != "Blog" ? null : el} className='mobile_nav_element' ref={reff => props.navBtnReff_M.current[id] = reff} key={id}
                                                // onMouseEnter={(e) => { if (id !== props.active_menu) { e.target.style.color = BG; e.target.style.background = clr3 } }}
                                                // onMouseLeave={(e) => { if (id !== props.active_menu) { e.target.style.color = text_color; e.target.style.background = "inherit" } }}
                                                onClick={() => {
                                                    if (!show_blog) { props.handleNavClickScroll(el); setTimeout(() => { props.setActiveMenu(id); }, 500); handleAttempt(el, id) }

                                                }}
                                                style={{
                                                    fontSize: handleTopNavButtonsStyle(id).fontSize, background: handleTopNavButtonsStyle(id).bg, color: handleTopNavButtonsStyle(id).clr,
                                                    padding: `${measure * 1}vw ${measure * 3}vw ${measure * 1}vw ${measure * 3}vw`, margin: "0 5px 0 5px",
                                                    borderRadius: `${measure * 1.5}vw`, textDecoration: "none",
                                                    transition: "background-color 0.9s, font-size 0.5s, color 0.5s",
                                                    transitionDelay: "0.22s", display: id == 0 ? "none" : "",
                                                }}
                                            >{el}</Link>
                                        )
                                    }
                                }
                                )}
                                {show_blog &&
                                    <div style={{ ...styles.flex_common2, marginRight: `${measure * 1}vw` }}>
                                        {n_mode ? <IoIosSunny title={nav_tooltip_messages.open_setting} size={top_icons_size}
                                            color={blog_colors_combination.blog_day_btn_color} onClick={() => { dispatch(ActionChangeDayNight({ n_mode: !n_mode })) }}

                                        /> :
                                            <MdOutlineDarkMode title={nav_tooltip_messages.open_setting} size={top_icons_size}
                                                color={blog_colors_combination.blog_nav_btn_bg_day} onClick={() => { dispatch(ActionChangeDayNight({ n_mode: !n_mode })) }}

                                            />
                                        }
                                    </div>}
                                {show_blog && props.blog_menus.map((el, id) =>
                                    <Link to={el !== "Blog" ? "/" : el} key={id} className={"nav1_top"}
                                        onClick={() => {
                                            if (el != "Blog") {
                                                props.setBlogVisibility(false)
                                            } else {
                                                props.setBlogVisibility(true)
                                            }
                                        }}
                                        style={{
                                            ...styles.flex_common2,
                                            fontSize: handleTopNavButtonsStyle(id).fontSize,
                                            background: el == "Vishal M" ? "" : n_mode ? blog_colors_combination.blog_nav_btn_bg_night : blog_colors_combination.blog_nav_btn_bg_day,
                                            transition: `background ${props.bg_transition_duration}ms`,
                                            color: el != "Vishal M" ? BG : n_mode ? blog_colors_combination.blog_nav_btn_font_color_night : blog_colors_combination.blog_nav_btn_font_color_day,
                                            borderRadius: handleTopNavButtonsStyle(id).borderRadius,
                                            padding: `${measure * 0.5}vh ${measure * 1}vh ${measure * 0.5}vh ${measure * 1}vh`,
                                            // marginRight: el == "Vishal M" ? `${measure * 1}vw` : "",
                                        }}
                                    >
                                        <div style={{ fontSize: `${measure * 2}vh` }}>{el}</div>
                                        {el == "Vishal M" &&
                                            <div style={{ height: `${measure * 4}vh`, marginLeft: `${measure * 2}vw` }}>
                                                <img src={self_thm1}
                                                    style={{
                                                        height: "100%", objectFit: "contain",
                                                        background: n_mode ? blog_colors_combination.blog_self_image_bg_night : blog_colors_combination.blog_self_image_bg_day,
                                                        borderRadius: `${measure * 2}vh`,
                                                    }} />
                                            </div>
                                        }
                                    </Link>
                                )}

                                {!show_blog && <div ref={props.reactIconRef_M} style={{ ...styles.flex_common2, height: "100%", }}>
                                    <FaReact className='r_icon' size={`${measure * 3.5}vh`} color={text_color}
                                        onClick={() => {
                                            props.handleSetting();
                                            props.reactIconRef_M.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
                                        }}
                                    /></div>}
                            </div>
                        </div>

                    </CSSTransition>
                    {!show_blog && props.active_menu ? <div
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center", marginRight: "2vw",
                            position: 'fixed', bottom: "3vh", left: `${measure * 1.5}vw`, opacity: 0.2, zIndex: 1
                        }}>
                        <VscFoldUp size={`${measure * 3}vh`} color={props.active_menu == (props.pages.length - 1) ? BG : text_color}
                            style={{ cursor: "pointer" }}
                            onClick={() => { props.handleNavClickScroll(props.pages[props.active_menu - 1]) }}
                        />
                    </div>
                        : ""
                    }

                    {!show_blog && (props.active_menu < (props.pages.length - 1)) && <div
                        style={{
                            display: "flex", justifyContent: "center", alignItems: "center", marginRight: "2vw",
                            position: 'fixed', bottom: "3vh", right: 0, opacity: 0.2, zIndex: 1
                        }}>
                        <VscFoldDown size={`${measure * 3}vh`} color={text_color}
                            style={{ cursor: "pointer" }}
                            onClick={() => { handleDownScroll_m() }}
                        />
                    </div>}
                </div>
            }

        </div >
    )
})

export default Nav
