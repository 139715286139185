import React, { memo, useLayoutEffect, useRef, useState } from 'react'
import { ImLink } from 'react-icons/im'
import { MdRemoveModerator } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { font_combinations, theme_combinations } from '../../global_store/theme_combinations'
import { work, work_title, work_traits } from '../model/data'
import './work.css'


const Work = memo(function Work(props) {
    const [work_filter, SetWorkfilterPC] = useState(false)
    const [clicked_workPC, setClickedWorkPC] = useState(work[0])
    const [clicked_workID_PC, setClickedWorkID_PC] = useState(0)
    const [clicked_workID_M, setClickedWorkID_M] = useState(0)
    const [filtered_Works, setFilteredWorksPC] = useState([])
    const [clicked_trait, setClickedTrait] = useState("")


    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8, clr9 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]

    let { BG, is_mobile1, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, card_text_style, measure } = props
    let dWidth = window.innerWidth
    let dHeight = window.innerHeight

    let styles = {
        flex_common1: { display: "flex", justifyContent: "center", },
        flex_common2: { display: "flex", justifyContent: "center", alignItems: "center" }
    }

    const fontSize = props.fontSize
    const pc_cards_fontsize = props.pc_cards_fontsize //pc

    const unclicked_card_bg = BG
    const clicked_card_bgg = !n_mode ? text_color : (theme == "thm1" && n_mode) ? BG : text_color
    const clicked_card_shadow = !n_mode ? clr6 : (theme == "thm1" && n_mode) ? clr5 : clr9
    const unclicked_card_shadow = text_color
    const clicked_card_color = !n_mode ? BG : (theme == "thm1" && n_mode) ? text_color : BG
    const unclicked_card_color = text_color


    const handleWorkFilterPc = (el, id) => {
        // handleScroll();
        function reset() {
            setClickedTrait("")
            setClickedWorkPC(""); setTimeout(() => { setClickedWorkPC(work[0]); }, 25)
            setClickedWorkID_PC(0)
            setClickedWorkID_M(0)
            SetWorkfilterPC(false)
        }
        if (el !== "Clear") {
            if (el !== clicked_trait) {
                let temp = work.filter((e) => e.type == el)
                setClickedTrait(el)
                setFilteredWorksPC(temp)
                setClickedWorkPC(""); setTimeout(() => { setClickedWorkPC(temp[0]); }, 25)
                setClickedWorkID_PC(0)
                setClickedWorkID_M(0)
                SetWorkfilterPC(true)
            } else {
                reset()
            }
        } else {
            reset()
        }
    }

    useLayoutEffect(() => {
        if (props.pages[props.previousRoutePage] == "Work" && props.pages[props.curr_route_page] != "Work") {
            handleWorkFilterPc("Clear");
            // handleWorkClick(false, work[0], 0, !is_mobile1)
        }
    }, [props.curr_route_page])



    const WorkUnfilteredListContentReff_M = useRef({})    // mobile
    const WorkFilteredListContentReff_M = useRef({})      // mobile
    const WorkUnfilteredListContentReff_Pc = useRef({})     //pc
    const WorkFilteredListContentReff_Pc = useRef({})       //pc

    const handleWorkClick = (filtered = false, el, id, is_pc = false) => {// card scroll handling
        setClickedWorkPC(""); setTimeout(() => { setClickedWorkPC(el); }, 25) // cleaning it first to start , new one from start of line
        setClickedWorkID_PC(id); setClickedWorkID_M(id)

        if (is_pc) {
            if (!filtered) {
                // setClieckedExpId(undefined); setClieckedEduId(id);
                props.handleCardVerticalScroll_Pc("Work", WorkUnfilteredListContentReff_Pc, el, id)
            } else {
                // setClieckedExpId(id); setClieckedEduId(undefined)
                props.handleCardVerticalScroll_Pc("Work", WorkFilteredListContentReff_Pc, el, id)
            }
        } else {
            if (!filtered) {
                props.handleCardsHorizontalScroll_M("Work", WorkUnfilteredListContentReff_M, el, id)
            } else {
                props.handleCardsHorizontalScroll_M("Work", WorkFilteredListContentReff_M, el, id)
            }
        }
    }





    return (
        <div id="Work" style={{ height: "100vh", width: "100vw", color: text_color, fontFamily: regular_text_style }}>
            {!is_mobile1 && //================================================= PC ONLY
                <div style={{ ...styles.flex_common2, alignItems: "flex-end", width: "100%", height: "100%", }}>
                    <div style={{
                        ...styles.flex_common2, width: "100%", height: "90%",
                    }}>
                        <div style={{ ...styles.flex_common2, flexDirection: "column", justifyContent: "space-between", width: "40%", height: "100%" }}>
                            <div style={{
                                ...styles.flex_common2, justifyContent: "flex-start", width: "100%", minWidth: "180px", height: "10%",
                                alignSelf: "flex-start", marginLeft: "3vw", fontSize: "3vh",
                            }}>
                                <div style={{
                                    ...styles.flex_common2, justifyContent: "flex-start", minWidth: "180px", height: "100%",
                                    alignSelf: "flex-start", fontSize: "3vh", position: "relative", width: "fit-content",
                                }}>
                                    {work_traits.map((e, i) =>
                                        <div key={i} onClick={() => handleWorkFilterPc(e, i)} style={{
                                            color: text_color, width: "fit-content", minWidth: "45px", cursor: "pointer",
                                            marginRight: "2vw", fontFamily: regular_text_style, position: "relative",

                                        }}>
                                            {e}
                                            {work_filter && clicked_trait == e &&
                                                <div onClick={() => handleWorkFilterPc("Clear")} style={{
                                                    color: text_color, cursor: "pointer",
                                                    fontFamily: regular_text_style, position: "absolute", right: "-1.1vw", top: "-2.5vh",

                                                }}>
                                                    <MdRemoveModerator style={{ height: "2vh" }} /></div>
                                            }
                                        </div>
                                    )}
                                    {/* {work_filter &&
                                    <div onClick={() => handleWorkFilterPc("Clear")} style={{
                                        color: text_color, width: "25%", minWidth: "50px", cursor: "pointer",
                                        fontFamily: regular_text_style, position: "absolute", right: "-5vh", top: "0vh",
                                    }}>
                                        <MdRemoveModerator style={{ height: "2vh" }} /></div>
                                } */}
                                </div>
                            </div>


                            <div style={{ ...styles.flex_common2, height: "90%", width: "100%", }} >
                                <div
                                    onClick={() => { const temp = clicked_workPC; setClickedWorkPC(""); setTimeout(() => { setClickedWorkPC(clicked_workPC); }, 25) }}
                                    style={{
                                        display: "flex", flexDirection: "column",
                                        height: "80%", width: "90%", overflowY: "auto", color: text_color,
                                        fontFamily: regular_text_style, fontSize: fontSize,
                                    }}><div className={"info_text"}
                                        style={{ fontSize: fontSize, }}
                                    >
                                        &nbsp;&nbsp;&nbsp;{clicked_workPC.info}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.flex_common2, width: "60%", height: "88%" }}>
                            {!work_filter && <div  // NO filter used
                                style={{ ...styles.flex_common2, width: "100%", maxHeight: "100%", fontSize: pc_cards_fontsize }}
                            >
                                <div style={{ ...styles.flex_common1, height: "87vh", width: "45%", overflowY: "auto", paddingBottom: "2vh" }}>
                                    <div
                                        style={{
                                            width: "90%", height: "fit-content",
                                        }}
                                    >
                                        {work.map((el, id) => {
                                            if (id <= 4) {
                                                return (
                                                    <div key={id}
                                                        ref={reff => WorkUnfilteredListContentReff_Pc.current[id] = reff}
                                                        onClick={() => { handleWorkClick(false, el, id, true) }}
                                                        style={{
                                                            ...styles.flex_common2, width: "100%", height: "40vh", padding: "2.7vh 0 2.7vh 0",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                ...styles.flex_common2,
                                                                height: "40vh", width: "100%",
                                                                borderRadius: "3vh", cursor: "pointer",
                                                                boxShadow: `0 0 1.2vh .6vh ${clicked_workID_PC != id ? clicked_card_shadow : unclicked_card_shadow}`,
                                                                // background: id == clicked_workID_PC ? clicked_card_bgg : unclicked_card_bg,
                                                                // color: id == clicked_workID_PC ? clicked_card_color : unclicked_card_color,
                                                            }}
                                                        >
                                                            <div style={{
                                                                ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                                width: "85%"
                                                            }}>
                                                                <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%", height: "20%" }}>
                                                                    <>{el.time}</>
                                                                    {el.link && <ImLink
                                                                        title={work_title}
                                                                        onClick={() => {
                                                                            window.open(el.link, '_blank')
                                                                        }}
                                                                    />}
                                                                </div>
                                                                <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                                <div style={{ ...card_text_style, height: "20%" }}>{el.skills.join(", ")}</div>
                                                                <div style={{ ...card_text_style }}>{el.info}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        )}

                                    </div>
                                </div>

                                <div style={{ ...styles.flex_common1, height: "87vh", width: "45%", overflowY: "auto", paddingBottom: "2vh" }}>

                                    <div
                                        style={{
                                            width: "90%", height: "fit-content",
                                        }}
                                    >
                                        {work.map((el, id) => {
                                            if (id > 4) {
                                                return (
                                                    <div key={id}
                                                        ref={reff => WorkUnfilteredListContentReff_Pc.current[id] = reff}
                                                        onClick={() => { handleWorkClick(false, el, id, true) }}
                                                        style={{
                                                            ...styles.flex_common2, width: "100%", height: "40vh", padding: "2.7vh 0 2.7vh 0",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                ...styles.flex_common2,
                                                                height: "40vh", width: "100%", borderRadius: "3vh", cursor: "pointer",
                                                                boxShadow: `0 0 1.2vh .6vh ${clicked_workID_PC != id ? clicked_card_shadow : unclicked_card_shadow}`, marginBottom: id == 2 ? "6vh" : "",
                                                                // background: id == clicked_workID_PC ? clicked_card_bgg : unclicked_card_bg,
                                                                // color: id == clicked_workID_PC ? clicked_card_color : unclicked_card_color,
                                                            }}
                                                        >
                                                            <div style={{
                                                                ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                                width: "85%"
                                                            }}>
                                                                <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%", height: "20%" }}>
                                                                    <>{el.time}</>
                                                                    {el.link && <ImLink
                                                                        title={work_title}
                                                                        onClick={() => {
                                                                            window.open(el.link, '_blank')
                                                                        }}
                                                                    />}
                                                                </div>
                                                                <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                                <div style={{ ...card_text_style, height: "20%" }}>{el.skills.join(", ")}</div>
                                                                <div style={{ ...card_text_style }}>{el.info}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                        )}

                                    </div>
                                </div>
                            </div>}



                            {work_filter &&  //  FILTER used
                                <div
                                    style={{ ...styles.flex_common2, width: "100%", maxHeight: "100%", fontSize: pc_cards_fontsize }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center", height: "87vh", width: "100%", overflowY: "auto", paddingBottom: "2vh" }}>
                                        <div
                                            style={{
                                                width: "85%", height: "fit-content", display: "flex", flexWrap: "wrap", justifyContent: "space-between",
                                                paddingBottom: "5vh",
                                            }}
                                        >
                                            {filtered_Works.map((el, id) => {
                                                if (true) {
                                                    return (
                                                        <div key={id} ref={reff => WorkFilteredListContentReff_Pc.current[id] = reff}
                                                            onClick={() => { handleWorkClick(true, el, id, true) }}
                                                            style={{
                                                                ...styles.flex_common2,
                                                                height: "40vh", width: "47.25%",
                                                                marginTop: (!id || id == 1 || (id && (((id % 4) != 0) && (id > 1 && ((id % 4) != 1))))) ? "6vh" : "",
                                                                //  marginBottom: id % 3 == 1 ? "6vh" : "",
                                                                borderRadius: "3vh", cursor: "pointer",
                                                                boxShadow: `0 0 1.2vh .6vh ${clicked_workID_PC != id ? clicked_card_shadow : unclicked_card_shadow}`, marginBottom: id == 2 ? "6vh" : "",
                                                                // background: id == clicked_workID_PC ? clicked_card_bgg : unclicked_card_bg,
                                                                // color: id == clicked_workID_PC ? clicked_card_color : unclicked_card_color,
                                                            }}
                                                        >
                                                            <div style={{
                                                                ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                                width: "85%"
                                                            }}>
                                                                <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%", height: "20%" }}>
                                                                    <>{el.time}</>
                                                                    {el.link && <ImLink
                                                                        title={work_title}
                                                                        onClick={() => {
                                                                            window.open(el.link, '_blank')
                                                                        }}
                                                                    />}
                                                                </div>
                                                                <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                                <div style={{ ...card_text_style, height: "20%" }}>{el.skills.join(", ")}</div>
                                                                <div style={{ ...card_text_style }}>{el.info}</div>
                                                            </div>

                                                        </div>)
                                                }
                                            }
                                            )}

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            }




            {
                is_mobile1 && //===============================================Mobile ONLY
                <div style={{ ...styles.flex_common2, alignItems: "flex-end", width: "100%", height: "100%" }}>
                    <div style={{
                        display: "flex", justifyContent: "space-between", width: "100%", height: "93%",
                        flexDirection: "column", alignItems: "center",
                    }}>

                        <div style={{
                            display: "flex", flexDirection: "column", justifyContent: "space-between",
                            width: "100%", height: "90%",
                        }}>
                            <div

                                onClick={() => { const temp = clicked_workPC; setClickedWorkPC(""); setTimeout(() => { setClickedWorkPC(clicked_workPC); }, 25) }}
                                style={{
                                    ...styles.flex_common2, height: "42%", paddingTop: "5vh", width: "90%", alignSelf: "center",
                                    fontSize: "4vh", overflowX: "auto"
                                }}
                            >
                                <div style={{
                                    ...styles.flex_common1,
                                    height: "90%", width: "100%", color: text_color,
                                    fontFamily: regular_text_style,
                                }}><div className={"info_text_m"}
                                    style={{ display: "inline", width: "90%" }}
                                >
                                        {clicked_workPC.info}
                                    </div>
                                </div>
                            </div>

                            {!work_filter && <div
                                style={{ ...styles.flex_common2, width: "100%", height: "45%", }}
                            >
                                <div style={{
                                    display: "flex", height: "100%", width: "100%", overflowY: "auto",
                                    paddingLeft: "5.5vw",
                                }}>
                                    <div
                                        style={{
                                            height: "fit-content", display: "flex", justifyContent: "space-between", padding: "2vh 0 0 0",
                                        }}
                                    >
                                        {work.map((el, id) => {
                                            if (true) {
                                                return (
                                                    <div key={id} ref={ref => WorkUnfilteredListContentReff_M.current[id] = ref}
                                                        onClick={() => { handleWorkClick(false, el, id) }}
                                                        style={{
                                                            ...styles.flex_common2, marginRight: `${measure * 6.5}vh`,
                                                            height: "33vh", width: "33vh",
                                                            borderRadius: "3vh", cursor: "pointer",
                                                            transition: `background ${props.bg_transition_duration}ms`,
                                                            boxShadow: `0 0 10px 5px ${clicked_workID_M != id ? clicked_card_shadow : unclicked_card_shadow}`,
                                                            // background: id == clicked_workID_M ? clicked_card_bgg : unclicked_card_bg,
                                                            // color: id == clicked_workID_M ? clicked_card_color : unclicked_card_color,
                                                        }}
                                                    >
                                                        <div style={{
                                                            ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                            width: "85%"
                                                        }}>
                                                            <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%", height: "20%" }}>
                                                                <>{el.time}</>
                                                                {el.link && <ImLink
                                                                    title={work_title}
                                                                    onClick={() => {
                                                                        window.open(el.link, '_blank')
                                                                    }}
                                                                />}
                                                            </div>
                                                            <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                            <div style={{ ...card_text_style, height: "20%" }}>{el.skills.join(", ")}</div>
                                                            <div style={{ ...card_text_style }}>{el.info}</div>
                                                        </div>

                                                    </div>)
                                            }
                                        }
                                        )}

                                    </div>
                                </div>
                            </div>
                            }

                            {work_filter && <div
                                style={{ ...styles.flex_common2, width: "100%", height: "45%", }}
                            >
                                <div style={{
                                    display: "flex", height: "100%", width: "100%", overflowY: "auto",
                                    paddingLeft: "5.5vw",
                                }}>
                                    <div
                                        style={{
                                            height: "fit-content", display: "flex", justifyContent: "space-between", padding: "2vh 0 0 0",
                                        }}
                                    >
                                        {filtered_Works.map((el, id) => {
                                            if (true) {
                                                return (
                                                    <div key={id} ref={ref => WorkFilteredListContentReff_M.current[id] = ref}
                                                        onClick={() => { handleWorkClick(true, el, id) }}
                                                        style={{
                                                            ...styles.flex_common2, marginRight: `${measure * 6.5}vh`,
                                                            height: "33vh", width: "33vh",
                                                            borderRadius: "3vh", cursor: "pointer",
                                                            // transition: `background ${props.bg_transition_duration}ms`,
                                                            boxShadow: `0 0 10px 5px ${clicked_workID_M != id ? clicked_card_shadow : unclicked_card_shadow}`,
                                                            // background: id == clicked_workID_M ? clicked_card_bgg : unclicked_card_bg,
                                                            // color: id == clicked_workID_M ? clicked_card_color : unclicked_card_color,
                                                        }}
                                                    >
                                                        <div style={{
                                                            ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                            width: "85%"
                                                        }}>
                                                            <div style={{ ...styles.flex_common2, justifyContent: "space-between", width: "100%", height: "20%" }}>
                                                                <>{el.time}</>
                                                                {el.link && <ImLink
                                                                    title={work_title}
                                                                    onClick={() => {
                                                                        window.open(el.link, '_blank')
                                                                    }}
                                                                />}
                                                            </div>
                                                            <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                            <div style={{ ...card_text_style, height: "20%" }}>{el.skills.join(", ")}</div>
                                                            {/* <div style={{ height: "20%" }}>{el.skills.join(", ")}</div> */}
                                                            <div style={{ ...card_text_style }}>{el.info}</div>
                                                        </div>

                                                    </div>)
                                            }
                                        }
                                        )}

                                    </div>
                                </div>
                            </div>
                            }
                        </div>



                        {props.work_btn_visibility_m && <div style={{ ...styles.flex_common2, width: "100%", height: "8%", marginBottom: "2vh", }}>
                            <div style={{
                                ...styles.flex_common2, justifyContent: "flex-start", height: "100%",
                                marginLeft: "3vw",
                            }}>
                                {work_traits.map((e, i) =>
                                    <div key={i} onClick={() => handleWorkFilterPc(e, i)} style={{
                                        color: text_color, width: "fit-content",
                                        fontFamily: regular_text_style, position: "relative", marginRight: "4vh"
                                    }}>
                                        {e}
                                        {work_filter && clicked_trait == e &&
                                            <div onClick={() => handleWorkFilterPc("Clear")} style={{
                                                color: text_color, width: "fit-content",
                                                fontFamily: regular_text_style, position: "absolute", right: "-2.5vw", top: "-2vh",
                                            }}>
                                                <MdRemoveModerator style={{ height: "1.7vh" }} /></div>
                                        }
                                    </div>
                                )}
                                {/* {work_filter &&
                                    <div onClick={() => handleWorkFilterPc("Clear")} style={{
                                        color: text_color, background: BG, width: "25%", minWidth: "50px",
                                        fontFamily: regular_text_style, position: "absolute", right: "-11vw", top: "1.3vh",
                                    }}>
                                        <MdRemoveModerator style={{ height: "2vh" }} /></div>
                                } */}
                            </div>
                        </div>}
                    </div>
                </div>
            }
        </div >
    )
})

export default Work