import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme_combinations, font_combinations } from '../../../../global_store/theme_combinations';
import BigHeading from './subUnits/bigheading';
import { useEffect } from 'react';
import Line from './subUnits/line';
import Bullet from './subUnits/Bullet';
import Code from './subUnits/Code';
import Small_heading from './subUnits/Small_heading';
import Bullet_link from './subUnits/Bullet_link';
import { ActionSetAmp } from '../../../../global_store/amp_reducer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const BlogContent = memo(function BlogContent(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    const { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    const { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    const { BG, is_mobile1, is_mobile3, text_color, measure, styles, setContents, b_contents } = props


    const content_types = ["Heading", "Line", "Bullet", "Code", "Small_heading", "Bullet_link"]


    const handleContentAddition = (content_type, position = b_contents.length, type) => {
        let temp = [...b_contents]
        let final = []
        if (content_type) {
            if (position !== 0) {
                if (content_type !== "Delete") {
                    if (type != "Code") {
                        temp.splice(position, 0, { [b_contents.length]: { content_type: content_type, value: "" } })
                    } else {
                        temp.splice(position, 0, { [b_contents.length]: { content_type: content_type, value: { code_lang: "", code: "" } } })
                    }
                } else {
                    temp.splice((position - 1), 1)
                }
            } else {
                temp = [...temp, { 1: { content_type: content_type, value: "" } }]
            }
            final = temp.map((e, i) => {
                let ell = { [i]: e[Object.keys(e)[0]] }
                return ell
            })
        } else {

        }
        setContents(final)
    }


    const editElement = (el, id, change, type) => {
        let temp = [...b_contents]
        if (type != "Code") {
            temp.splice(id, 1, { [id]: { content_type: el[Object.keys(el)[0]].content_type, value: change.target.value } })
        } else {
            temp.splice(id, 1, { [id]: { content_type: el[Object.keys(el)[0]].content_type, value: { code_lang: change.code_lang, code: change.code } } })
        }
        setContents(temp)
    }

    useEffect(() => {
        // setContents([])
    }, [])


    return (
        <div style={{ ...styles.flex_common5, width: "100%", }}>
            {
                // !is_mobile1 &&
                <div style={{ width: "100%", marginTop: `${measure * 5}vh`, }}> {/* PC */}
                    {b_contents.length ?
                        <div>
                            {
                                b_contents.map((el, id) => {
                                    if (el[Object.keys(el)[0]].content_type == "Heading") {
                                        return <BigHeading key={id}
                                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                            b_contents={b_contents} setContents={setContents} content_types={content_types} idd={id}
                                            editElement={(change) => { editElement(el, id, change) }} AddNewInBetween={(content_type) => { handleContentAddition(content_type, id + 1) }}
                                        />
                                    }
                                    if (el[Object.keys(el)[0]].content_type == "Line") {
                                        return <Line key={id}
                                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                            b_contents={b_contents} setContents={setContents} content_types={content_types} idd={id}
                                            editElement={(change) => { editElement(el, id, change) }} AddNewInBetween={(content_type) => { handleContentAddition(content_type, id + 1) }}
                                        />
                                    }
                                    if (el[Object.keys(el)[0]].content_type == "Bullet") {
                                        return <Bullet key={id}
                                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                            b_contents={b_contents} setContents={setContents} content_types={content_types} idd={id}
                                            editElement={(change) => { editElement(el, id, change) }} AddNewInBetween={(content_type) => { handleContentAddition(content_type, id + 1) }}
                                        />
                                    }
                                    if (el[Object.keys(el)[0]].content_type == "Code") {
                                        return <Code key={id}
                                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                            b_contents={b_contents} setContents={setContents} content_types={content_types} idd={id}
                                            editElement={(change) => { editElement(el, id, change, "Code") }} AddNewInBetween={(content_type) => { handleContentAddition(content_type, id + 1, "Code") }}
                                        />
                                    }
                                    if (el[Object.keys(el)[0]].content_type == "Small_heading") {
                                        return <Small_heading key={id}
                                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                            b_contents={b_contents} setContents={setContents} content_types={content_types} idd={id}
                                            editElement={(change) => { editElement(el, id, change) }} AddNewInBetween={(content_type) => { handleContentAddition(content_type, id + 1) }}
                                        />
                                    }
                                    if (el[Object.keys(el)[0]].content_type == "Bullet_link") {
                                        return <Bullet_link key={id}
                                            BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure} styles={styles}
                                            b_contents={b_contents} setContents={setContents} content_types={content_types} idd={id}
                                            editElement={(change) => { editElement(el, id, change) }} AddNewInBetween={(content_type) => { handleContentAddition(content_type, id + 1) }}
                                        />
                                    }
                                })
                            }
                        </div> : ""}
                    <div style={{ ...styles.flex_common2, justifyContent: "space-evenly", width: "100%", marginBottom: "12vh" }}>
                        {content_types.map((content_type, id) =>
                            <button style={{ marginTop: "7vh", alignSelf: "flex-start", height: "5vh", fontSize: "2vh", cursor: "pointer" }}
                                onClick={() => { handleContentAddition(content_type, b_contents.length) }}
                            >{`Add ${content_type}`}</button>
                        )}

                    </div>
                </div>}

            {is_mobile1 && <div style={{}}>{/* Phone */}
            </div >}
        </div>

    )
})

export default BlogContent
