import React from 'react';
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const amp = useSelector((state) => state.AllReducerCombined.ampReducer.amp)
    let location = useLocation();
    if (!amp) {
        return <Navigate to='/Blog/ampmin2--/login' state={{ from: location }} replace />
    }
    return children
};

export default ProtectedRoute;