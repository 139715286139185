import { createSlice } from '@reduxjs/toolkit'

export const themeChange = createSlice({
    name: 'theme_change',
    initialState: {
        active_theme: "thm3",
        night_mode: true,
        active_font_comb: "f_comb1"
    },
    reducers: {
        ActionChangeTheme: (state, incoming_changes) => {
            let update = incoming_changes?.payload.active_theme
            return { ...state, active_theme: update }
        },
        ActionChangeDayNight: (state, incoming_changes) => {
            let update = incoming_changes?.payload.n_mode
            // console.log("updated : ", { ...state, night_mode: update })
            return { ...state, night_mode: update }
        },
        ActionChangeFont: (state, incoming_changes) => {
            let update = incoming_changes?.payload.active_font_comb
            // console.log("updated : ", { ...state, night_mode: update })
            return { ...state, active_font_comb: update }
        }
    },
})

// Action creators are generated for each case reducer function
export const { ActionChangeTheme, ActionChangeDayNight, ActionChangeFont } = themeChange.actions

export default themeChange.reducer