import React, { memo, useEffect, useState } from 'react'
import { BsWhatsapp } from 'react-icons/bs'
import { IoIosCall } from 'react-icons/io'
import { IoLocationSharp, IoLogoLinkedin } from 'react-icons/io5'
import { SiGmail, SiHackerrank } from 'react-icons/si'
import { TbWorldWww } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { font_combinations, theme_combinations } from '../../global_store/theme_combinations'
import { contact } from '../model/data'
import './contact.css'


const Contact = memo(function Contact(props) {

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]

    let { BG, is_mobile1, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, is_mobile3 } = props
    let dWidth = window.innerWidth
    let dHeight = window.innerHeight

    let styles = {
        flex_common1: { display: "flex", justifyContent: "center", },
        flex_common2: { display: "flex", justifyContent: "center", alignItems: "center" }
    }


    const measure = 28
    const pc_mail_heading_font_size = `${measure * 1.5}px`
    const pc_reach_me_font = `${measure * 2}px`
    const pc_input_font_size = measure //pc
    const pc_input_font_sizee = `${pc_input_font_size}px` //pc
    const pc_input_min_height = `${pc_input_font_size * 1.3}px` //pc
    const pc_input_padding_v = `${pc_input_font_size / 5}px`
    const pc_input_padding_h = `${pc_input_font_size / 2}px`
    const input_active_brdr = `1px solid ${text_color}`
    const input_inactive_brdr = `1px solid gray`
    const pc_input_bg = text_color
    const pc_input_color = BG
    const pc_textarea_hight = `${pc_input_font_size * 10}px`
    const pc_inActive_opc = 0.8;
    const pc_inActive_placeholder_clr = n_mode ? clr6 : clr8;
    const pc_input_brdr_radius = `${pc_input_font_size}px`


    const pc_mail_btn_style = {
        ...styles.flex_common2, border: 0, outline: 0, height: pc_input_min_height, color: BG, fontFamily: regular_text_style, background: text_color,
        fontSize: pc_input_font_size, opacity: pc_inActive_opc, borderRadius: `${pc_input_font_size / 2}px`,
        padding: `${measure * 0.8}px ${measure * 1.5}px ${measure * 0.8}px ${measure * 1.5}px`, cursor: "pointer",
    }

    const pc_input_common_styles = {
        outline: 0, width: "100%", border: input_inactive_brdr, borderRadius: pc_input_brdr_radius,
        fontFamily: "inherit", background: pc_input_bg, fontSize: "inherit",
        height: pc_input_min_height, padding: `${pc_input_padding_v} ${pc_input_padding_h} ${pc_input_padding_v} ${pc_input_padding_h}`,
    }


    const decideImage = (el, id, call_or_whatsapp) => {
        const size = "3.5vh"
        if (el.text == "Call") {
            return <BsWhatsapp size={size} />
        }
        if (el.text == "Office") {
            return <IoLocationSharp size={size} />
        }
        if (el.text == "Mail") {
            return <SiGmail size={size} />
        }
        if (el.text == "Find Me On Linkdin") {
            return <IoLogoLinkedin size={size} />
        }
        if (el.text == "Website") {
            return <TbWorldWww size={size} />
        }
        if (el.text == "HackerRank") {
            return <SiHackerrank size={size} />
        }
        if (call_or_whatsapp == "whatsapp") {
            return <BsWhatsapp size={size} />
        }
        if (call_or_whatsapp == "phone") {
            return <IoIosCall size={size} />
        }
    }

    const [call_or_whatsapp_flag_M, setCallorWhatsappFlag] = useState(false)
    useEffect(() => {
        if (call_or_whatsapp_flag_M) {
            setTimeout(() => { setCallorWhatsappFlag(false) }, 7000)
        }
    }, [call_or_whatsapp_flag_M])

    return (
        <div id="Contact" style={{ ...styles.flex_common2, width: "100vw", }}>
            {!is_mobile1 && // PC
                <div style={{ display: "flex", flexDirection: "column", fontFamily: regular_text_style, color: text_color, height: "100%", width: "100%", }}>

                    <div style={{ display: "flex", flexDirection: "column", height: "50vh", width: "100%", }}>
                        <div style={{
                            height: "2.5vh", width: "100%",
                            //  background: text_color, opacity: 0.7
                        }}></div>
                        <div style={{ // ==================== ================= PC FOOTER
                            ...styles.flex_common2, height: "47.5vh", width: "100%", opacity: props.contactClickRef.current ? 1 : n_mode ? 0.8 : 1,
                            background: props.contactClickRef.current ? BG : text_color, color: props.contactClickRef.current ? text_color : BG,
                            // borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh"
                            flexDirection: 'column',
                        }}>
                            {/* <div style={{ width: "100%", height: "10%", background: BG, }}></div> */}
                            <div style={{
                                ...styles.flex_common2, height: "75%", width: "100%", flexWrap: "wrap",
                            }}>
                                {contact.map((el, id) =>
                                    <div className='contact_elm' key={id}
                                        style={{
                                            ...styles.flex_common2, justifyContent: "space-between", fontFamily: clr6, fontSize: "2.5vh",
                                            width: is_tablet1 ? "33.33%" : "16.66%",

                                        }}>
                                        <div onClick={() => {
                                            if (el.link) { window.open(el.link, '_blank'); }
                                            else if (el.text == "Website") { props.handleNavClickScroll("Home") }
                                        }}
                                            style={{
                                                ...styles.flex_common2, alignItems: "center", flexDirection: "column", height: "85%",
                                                width: "100%", cursor: "pointer", borderRadius: "2vh",
                                            }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common2, flexDirection: "column",
                                                    padding: "2vh", backgroundColor: "inherit"
                                                }}>
                                                <div style={{ marginBottom: "2.5vh", backgroundColor: "inherit" }}>
                                                    {decideImage(el, id)}
                                                </div>
                                                {/* <div style={{ height: "25%" }}>{el.text}</div> */}
                                                <div style={{ backgroundColor: "inherit" }}>
                                                    {el.text==="Website"?`${window.location.hostname.includes("www")?"":"www."}${window.location.hostname}`:el.detail}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )

                                }
                            </div>
                        </div>
                    </div>
                </div>}





            {is_mobile1 && // mobile
                <div className='footer_main_p' style={{
                    display: "flex", flexDirection: "column", fontFamily: regular_text_style, color: text_color, height: "92vh", width: "100%",
                    opacity: props.contactClickRef.current ? 1 : n_mode ? 0.8 : 1,
                    background: props.contactClickRef.current ? BG : text_color, color: props.contactClickRef.current ? text_color : BG,
                    overflow: "auto"
                }}>

                    <div style={{ // ==================== ================= // mobile
                        ...styles.flex_common2, height: "100%", width: "100%",
                        // borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh"
                        flexDirection: 'column',
                    }}>
                        {/* <div style={{ width: "100%", height: "10%", background: BG, }}></div> */}
                        <div style={{
                            ...styles.flex_common2, height: "100%", width: "100%", flexWrap: "wrap",
                        }}>
                            {contact.map((el, id) => {
                                if (el.text == "Call") {
                                    if ((call_or_whatsapp_flag_M)) {
                                        return (<div key={el.text} className='contact_elm'
                                            style={{
                                                ...styles.flex_common2, justifyContent: "space-between", fontFamily: clr6, fontSize: "2.5vh",
                                                width: "100%",

                                            }}>
                                            {[4343, 43434].map((ell, idd) =>
                                                <a key={idd.toString() + el.text} href={(idd) ? el.app_uri_scheme_phone : el.uri_scheme_phone_call}
                                                    onClick={() => { setTimeout(() => { setCallorWhatsappFlag(false) }, 500) }}
                                                    style={{
                                                        ...styles.flex_common2, alignItems: "center", flexDirection: "column", height: "85%",
                                                        width: "100%", cursor: "pointer", borderRadius: "2vh", textDecoration: "none",
                                                        color: "inherit",
                                                    }}>
                                                    <div
                                                        style={{
                                                            ...styles.flex_common2, flexDirection: "column",
                                                            padding: "2vh", backgroundColor: "inherit"
                                                        }}>
                                                        <div style={{ marginBottom: "2.5vh", backgroundColor: "inherit" }}>
                                                            {decideImage({}, -1, idd ? "whatsapp" : "phone")}
                                                        </div>
                                                        <div style={{ backgroundColor: "inherit" }}>
                                                            {!idd ? el.text : el.text_2_p}
                                                        </div>
                                                    </div>
                                                </a>
                                            )}
                                        </div>)
                                    }

                                    else {
                                        return (<div key={el.text} className='contact_elm'
                                            style={{
                                                ...styles.flex_common2, justifyContent: "space-between", fontFamily: clr6, fontSize: "2.5vh",
                                                width: "100%",

                                            }}>
                                            <div key={el.text + "fseffe"}
                                                onClick={() => { setCallorWhatsappFlag(true) }}
                                                style={{
                                                    ...styles.flex_common2, alignItems: "center", flexDirection: "column", height: "85%",
                                                    width: "100%", cursor: "pointer", borderRadius: "2vh", textDecoration: "none",
                                                    color: "inherit",
                                                }}>
                                                <div
                                                    style={{
                                                        ...styles.flex_common2, flexDirection: "column",
                                                        padding: "2vh", backgroundColor: "inherit"
                                                    }}>
                                                    <div style={{ marginBottom: "2.5vh", backgroundColor: "inherit" }}>
                                                        {decideImage(el, id)}
                                                    </div>
                                                    {/* <div style={{ height: "25%" }}>{el.text}</div> */}
                                                    <div style={{ backgroundColor: "inherit" }}>
                                                        {el.text==="Website"?`${window.location.hostname.includes("www")?"":"www."}${window.location.hostname}`:el.detail}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                }


                                else {
                                    return (<div key={el.text} className='contact_elm'
                                        style={{
                                            ...styles.flex_common2, justifyContent: "space-between", fontFamily: clr6, fontSize: "2.5vh",
                                            width: "100%",

                                        }}>
                                        <a href={(el.app_uri_scheme_phone && is_mobile3) && el.app_uri_scheme_phone} // Opening App with App scheme in case of mobile and redirecting to site in case of tablets
                                            onClick={() => {
                                                { setCallorWhatsappFlag(false) }
                                                if (!(el.app_uri_scheme_phone && is_mobile3) && el.link && el.text != "Website") {
                                                    window.open(el.link, "_blank")
                                                }
                                                if (el.text == "Website") { props.handleNavClickScroll("Home") }
                                            }}
                                            style={{
                                                ...styles.flex_common2, alignItems: "center", flexDirection: "column", height: "85%",
                                                width: "100%", cursor: "pointer", borderRadius: "2vh", textDecoration: "none",
                                                color: "inherit",
                                            }}>
                                            <div
                                                style={{
                                                    ...styles.flex_common2, flexDirection: "column",
                                                    padding: "2vh", backgroundColor: "inherit"
                                                }}>
                                                <div style={{ marginBottom: "2.5vh", backgroundColor: "inherit" }}>
                                                    {decideImage(el, id)}
                                                </div>
                                                {/* <div style={{ height: "25%" }}>{el.text}</div> */}
                                                <div style={{ backgroundColor: "inherit" }}>
                                                    {el.text==="Website"?`${window.location.hostname.includes("www")?"":"www."}${window.location.hostname}`:el.detail}
                                                </div>
                                            </div>
                                        </a>
                                    </div>)
                                }
                            }
                            )

                            }
                        </div>
                    </div>
                </div>}
        </div >
    )
})

export default Contact
{/* 
<div style={{  }}></div> 
 */}
{/* 
<div style={{ ...styles.flex_common2 }}>

</div> 
*/}