
import React, { memo, useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { IoIosSunny } from "react-icons/io";
import { IoMoonSharp, } from "react-icons/io5";
import { MdOutlineDarkMode } from "react-icons/md";
import { TfiShiftLeftAlt, TfiShiftRightAlt } from "react-icons/tfi";
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Route, Routes, useLocation, useNavigate, } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { blog_colors_combination, theme_combinations as colors, font_combinations } from '../global_store/theme_combinations';
import { ActionChangeDayNight, ActionChangeFont, ActionChangeTheme } from '../global_store/theme_reducer';
import './LandPage.css';
import Nav from './Nav';
import { nav_tooltip_messages } from './model/data';
import About from './subPages/About';
import Contact from './subPages/Contact';
import Home from './subPages/Home';
import Skills from './subPages/Skills';
import Work from './subPages/Work';
import BlogIndex from './subPages/Blog/BlogIndex';
import BlogCard from './subPages/Blog/blogCard';
import Lgn from './subPages/Blog/Lgn';
import Amp from './subPages/Blog/Amp';
import ProtectedRoute from '../routes/ProtectedRoute';
import { ActionSetAmp } from '../global_store/amp_reducer';
import { errorHandling } from '../Utils/API/errorHandling';
import axios from 'axios';
import Blog from './subPages/Blog/Blog';
import { base_url } from '../config/env';
import { Helmet } from 'react-helmet-async';

function usePrevious(value) { //custom hooks for saving previous state or props
    const OldRef = useRef(value)
    useLayoutEffect(() => {
        OldRef.current = value
    })
    return OldRef.current
}



const LandPage = memo(function LandPage(props) {
    const location = useLocation()
    const [curr_route_page, setCurrRoutePage] = useState(0)
    const contactClickRef = useRef(false)//PC Footer colors handling
    const previousRoutePage = usePrevious(curr_route_page)
    const dispatch = useDispatch()
    const [top_nav, toggleTopNav] = useState(false)
    const [nav, setNav] = useState("top")
    const [work_btn_visibility_m, setWorkBtn_M] = useState(true)
    const [setting_modal, setSettingModal] = useState(false)
    const [active_menu, setActiveMenu] = useState(NaN)
    const [swap_setting_color_M, setSwapSettingColor_M] = useState(false)
    const [show_blog, setBlogVisibility] = useState(false)
    const direct_contact_open_Ref_M = useRef(false)
    // const pages = ["", "About", "Skills",Experience, "Work", "Contact"]
    const YscrollRef = useRef(undefined)
    const reactIconRef_M = useRef()                // phone
    const navBtnReff_M = useRef({})                // phone
    const settingRef = useRef(null)
    const [y_scroll, setYScroll] = useState(0)

    const is_high_pc1 = useMediaQuery({ query: `(min-width: 2048px)` });
    const is_mid_high_pc1 = useMediaQuery({ query: `(min-width: 1600px)` });
    const is_low_pc1 = useMediaQuery({ query: `(max-width: 1250px)` });
    const is_tablet1 = useMediaQuery({ query: `(max-width: 1020px)` });
    const is_tablet2 = useMediaQuery({ query: `(max-width: 800px)` });
    let is_mobile3 = useMediaQuery({ query: `(max-width: 549px)` });
    let is_mobile1 = useMediaQuery({ query: `(max-width: 780px)` });
    const is_mobile2 = useMediaQuery({ query: `(max-width: 300px)` });
    const side_setting_off = useMediaQuery({ query: `(min-width: 1921px)` });

    const navigate = useNavigate();
    const [discrete_scroll_value, setDiscreteScroll] = useState(40)

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)

    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6 } = colors[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style, card_texts_font_style } = font_combinations[active_font_comb]
    let BG = n_mode ? show_blog ? blog_colors_combination.blog_night_bg : bg_night : show_blog ? blog_colors_combination.blog_day_bg : bg_day
    // let BG = n_mode ? bg_night : bg_day
    let BG_BLOG = n_mode ? blog_colors_combination.blog_night_bg : blog_colors_combination.blog_day_bg
    let text_color = n_mode ? show_blog ? blog_colors_combination.blog_day_bg : clr1 : show_blog ? blog_colors_combination.blog_night_bg : clr4
    // let text_color = n_mode ? clr1 : clr4
    let text_color_blog = n_mode ? blog_colors_combination.blog_day_bg : blog_colors_combination.blog_night_bg
    let ball_size = 5.5 //pc
    let setting_icons_size = is_high_pc1 ? "4vh" : is_mid_high_pc1 ? "4vh" : "4vh" //pc
    // const fontSize = "2vw";
    const measure = 1 //pc
    const fontSize = `${measure * 5}vh` //pc
    const pc_cards_fontsize = `${measure * 2.2}vh`; //pc
    const bg_transition_duration = 1200

    const card_text_style = { height: "50%", overflowY: "auto", wordBreak: "break-word", fontFamily: card_texts_font_style }
    const styles = {
        flex_common1: { display: "flex", justifyContent: "center", },
        flex_common2: { display: "flex", justifyContent: "center", alignItems: "center" },
        flex_common3: { display: "flex", flexDirection: "column", justifyContent: "center", },
        flex_common4: { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" },
        flex_common5: { display: "flex", flexDirection: "column", alignItems: "center", },
        flex_common6: { display: "flex", flexDirection: "column", },
    }


    const render1 = useMediaQuery({ query: `(max-width: 570px)` }); // change in value of useMediaQuery causes a re-render
    const render2 = useMediaQuery({ query: `(max-width: 610px)` });
    const render3 = useMediaQuery({ query: `(max-width: 635px)` });
    const render3_1 = useMediaQuery({ query: `(max-width: 647px)` });
    const render4 = useMediaQuery({ query: `(max-width: 665px)` });
    const render5 = useMediaQuery({ query: `(max-width: 720px)` });
    const render6 = useMediaQuery({ query: `(max-width: 750px)` });
    const render7 = useMediaQuery({ query: `(max-width: 780px)` });


    if ((process.env.NODE_ENV == "development") && ((window.innerWidth / window.innerHeight) < 0.8)) { // =====DESKTOP MODE IN MOBILE HANDLING 
        is_mobile1 = true // is_mobile1 is just a let variable , so it's needs a component render to activate the changes 
    }
    else if ((process.env.NODE_ENV != "development") && ((window.innerWidth / window.innerHeight) < 0.85)) {// development mode have height of 950 but in prod it have 764
        is_mobile1 = true // is_mobile1 is just a let variable , so it's needs a component render to activate the changes 
    }

    const pages = is_mobile1 ? [" ", "About", "Skills", "Work", "Contact", "Blog"] : [" ", "About", "Skills", "Work", "Blog"]
    const blog_menus = [...pages.filter((el) => el == "Blog"), "Vishal M",]


    useLayoutEffect(() => {

        Top();
        if (location?.pathname && location.pathname.split("/")[1]) { // FOR Auto scroll to the target sub-screen when user searched with subroutes name
            const screen = location.pathname.split("/")[1]
            handleNavClickScroll(screen)

            if (screen == "Contact") {// mobile setting bar color handling 
                direct_contact_open_Ref_M.current = true
            }
        }

        if (is_mobile3) {
            if (active_font_comb == "f_comb1") {
                // dispatch(ActionChangeFont({ active_font_comb: "f_comb2" }))
                // dispatch(ActionChangeDayNight({ n_mode: false }))
            }
        }
        if (!is_mobile3) {
            if (active_font_comb == "f_comb2") {
                dispatch(ActionChangeFont({ active_font_comb: "f_comb1" }))
            }
        }
    }, [])


    useLayoutEffect(() => {
        if (!nav && y_scroll > 40) { // if user scrolls down when only TRIPLE-BAR is visible, So setting Top NAV ON 
            setNav("top")
        }
    }, [y_scroll])

    useLayoutEffect(() => {
        if (contactClickRef.current && curr_route_page < pages.length - 1 && curr_route_page < previousRoutePage) { //PC Footer colors handling
            contactClickRef.current = false
            if (is_mobile1) {// mobile setting bar color handling 
                direct_contact_open_Ref_M.current = false
            }
        }
        handleNavHorizontalScroll()// phone nav button scroll into view handlind
    }, [curr_route_page])


    const Top = () => {
        // navigate({ pathname: "/" })
        setActiveMenu(0)
        handleNavClickScroll("Home")
    }

    const handleNavClickScroll = (screen, screen_id, style_footer = true) => { // scroll to the start of sub-screens
        if (!screen) { // when nav Align button pressed
            let element = document.getElementById(pages[active_menu]);
            if (curr_route_page == 0 && YscrollRef.current.scrollTop < YscrollRef.current.clientHeight) {
                element = document.getElementById("Home");
            }
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
        const element = document.getElementById(screen);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        if (screen == "Contact" && curr_route_page !== pages.length && style_footer) { //PC Footer/Contact colors handling
            // setContactClicked(true)
            contactClickRef.current = true
        }
    };

    const handleScroll = async (e) => { // change routename and nav button style as per scroll 
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        let before_scroll_margin = (window.innerHeight / 2) + 450 // decide how much prior visiting to subscreen's Top, you want to change (route path & nav button styles)
        let route_index = Math.abs(Math.trunc((e.target.scrollHeight - e.target.scrollTop - before_scroll_margin) / e.target.clientHeight) - (pages.length - 2))
        if (!show_blog) {
            if (is_mobile1) {
                // before_scroll_margin = (window.innerHeight / 2)
                before_scroll_margin = (window.innerHeight / 3)
                route_index = Math.abs(Math.trunc((e.target.scrollHeight - e.target.scrollTop - before_scroll_margin) / e.target.clientHeight) - (pages.length - 2))
            }

            if (Math.trunc(route_index) !== curr_route_page) { // For changing route name in sync with scroll
                // navigate({ pathname: route_index == 0 ? "/" : pages[route_index] })
                setActiveMenu(route_index)
                setCurrRoutePage(route_index)
            }

            const scrollY = window.scrollY
            const scrollTop = YscrollRef.current.scrollTop;

            if (is_mobile1) {
                if (((e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight + 10) && work_btn_visibility_m) {
                    setWorkBtn_M(false)
                } else if (!((e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight + 10) && !work_btn_visibility_m) {
                    setWorkBtn_M(true)

                }
            }
            // console.log("e : ", e)
            // console.log("bottom : ", bottom)
            // console.log("e.target.scrollHeight : ", e.target.scrollHeight)
            // console.log("e.target.scrollTop : ", e.target.scrollTop)
            // console.log("e.target.clientHeight : ", e.target.clientHeight)
            // console.warn("e.target.scrollHeight - scrollTop : ", e.target.scrollHeight - scrollTop)
            // console.warn("route_index : ", route_index)
            // console.warn("curr_route_page : ", curr_route_page)
            // console.warn("active_menu : ", active_menu)
            // console.log("Scrolltop : ", scrollTop)
            // console.log("YscrollRef.current.scrollHeight : ", YscrollRef.current.scrollHeight)


            if (nav == "left") { // If scroll detected hide SideBar
                if (scrollTop > 40) {
                    if (nav != "top") {
                        await setYScroll(scrollTop)
                        await setActiveMenu(active_menu)
                        setNav("top")
                    }
                }
            }
            if (nav != "top") {
                setYScroll(scrollTop)
            }
            if (nav == "top" && scrollTop == 0) { // to make top nav's side button visible (if user scrolls top of screen and if top nav is visible)
                setYScroll(0)
            }

            controlledRerenderOnScroll(scrollTop)

            if (!direct_contact_open_Ref_M.current) { // mobile setting bar color handling 
                if (((e.target.scrollHeight - scrollTop - 1) < (2 * e.target.clientHeight - 100)) && !swap_setting_color_M) {
                    setSwapSettingColor_M(true)
                } if (((e.target.scrollHeight - scrollTop - 1) > (2 * e.target.clientHeight - 100)) && (swap_setting_color_M)) {
                    direct_contact_open_Ref_M.current = false
                    setSwapSettingColor_M(false)
                }
            }
        }
    }

    const controlledRerenderOnScroll = (scrollTop) => { // for re-rendering only once in some scroll range
        const quantization = 100
        if (Math.abs(scrollTop - discrete_scroll_value) >= quantization) {
            setDiscreteScroll(Math.trunc(scrollTop / quantization) * quantization)
            setYScroll(scrollTop)
            // handleNavHorizontalScroll() // phone nav button scroll into view handlind
        }
    }


    const handleSetting = () => {
        setSettingModal(true)
    }

    const handleThemeChange = (thm) => {
        dispatch(ActionChangeTheme({ active_theme: thm }))
    }


    const handleDayNight = () => {
        dispatch(ActionChangeDayNight({ n_mode: !n_mode }))
    }

    const handleChangeFontStyles = (font_comb) => {
        dispatch(ActionChangeFont({ active_font_comb: font_comb }))
    }


    const handleCardsHorizontalScroll_M = (screen, reff, el, id) => { // for mobile, handling horizontal scrolling of cards and vertical scroll of the focussed screen
        reff.current[id].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
        setTimeout(() => { handleNavClickScroll(screen) }, 1200)
    }

    const handleCardVerticalScroll_Pc = (screen, reff, el, id) => { //for pc, handling horizontal scrolling of cards and vertical scroll of the focussed screen
        reff.current[id].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
        setTimeout(() => { handleNavClickScroll(screen) }, 550)
    }

    const handleNavHorizontalScroll = () => {
        if (is_mobile1) {
            if (curr_route_page >= 3) {// phone nav button scroll into view handlind

                setTimeout(() => { reactIconRef_M.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }) }, 650)
            }
            if (curr_route_page >= 3) {// phone nav button scroll into view handlind

                // setTimeout(() => { navBtnReff_M.current[3].scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }) }, 650)
            } else if (curr_route_page <= 1) {
                setTimeout(() => { navBtnReff_M.current[1].scrollIntoView({ behavior: "smooth", block: "start", inline: "start" }) }, 650)
            }
        }
    }

    useEffect(() => {// It triggered a warning by changing the state "show_blog", so right next hook can pick this warning
        if (show_blog && !(location?.pathname && location.pathname.split("/")[1])) {
            setBlogVisibility(false)
        }
        if (!show_blog && (location?.pathname && location.pathname.split("/")[1] == "Blog")) {
            setBlogVisibility(true)
        }
    }, [location?.pathname && location.pathname.split("/")])

    useEffect(() => {// for re-chaging let type BG and textcolor variable when the state "show_blog changed"
        BG = n_mode ? show_blog ? blog_colors_combination.blog_night_bg : bg_night : show_blog ? blog_colors_combination.blog_day_bg : bg_day
        text_color = n_mode ? show_blog ? blog_colors_combination.blog_day_bg : clr1 : show_blog ? blog_colors_combination.blog_night_bg : clr4
    }, [show_blog])


    const [blog_highLights, setBloghighLights] = useState([])
    useEffect(() => { getBlogsHighlights() }, [])
    const getBlogsHighlights = () => {
        const base_urll = base_url
        const url = `${base_urll}/blog/getBloghighLights`
        axios.get(url).then((res) => {
            if (res.status == 200) {
                setBloghighLights(res.data)
            }
        }).catch((err) => {
            //console.log({ err });
            if (errorHandling(err) == "LOGOUT") {
                dispatch(ActionSetAmp({ amp: 0 }))
                localStorage.setItem("token", "")
            }
        })
    }


    useLayoutEffect(() => { YscrollRef.current.scrollTo(0, 0) }, [show_blog]) // It Adjusts Scroll to Top When Coming to or leaving the blog page


    // console.warn("render :", location?.pathname && location.pathname.split("/"), !!(location?.pathname && location.pathname.split("/")[1]));
    // console.warn("process.env == ", process.env)
    // console.warn("active menu :", active_menu, "PH : ", is_mobile1, "\n:TAB : ", is_tablet1)
    // { console.log("window.innerWidth : ", window.innerWidth) }
    // { console.log("curr_route_page : ", curr_route_page); }
    // console.log("previousRoutePage : ", previousRoutePage, "\n curr_route_page :", curr_route_page)
    // console.log("nav : ", nav)
    // console.log("window : ", window.matchMedia(`(max-width: 1250px)`))
    // console.log("y_scroll : ", y_scroll)
    // console.log("show_triple_bar : ", y_scroll, y_scroll > 40 ? false : true)
    // console.log("height: ", window.innerHeight, "\ninnerwidht : ", window.innerWidth, "\n ratio", (window.innerWidth / window.innerHeight))
    // console.log("font ", font_combinations[active_font_comb], "\nactive_font_comb : ", active_font_comb)


    return (<div className="container" ref={YscrollRef} onScroll={handleScroll} style={{ background: BG, transition: `background ${bg_transition_duration}ms`, overflowX: "auto" }} >
        {/* <TestFunctional /> */}
        {/* <TestClass /> */}
        <div onClick={() => { setting_modal && setSettingModal(false) }}
            className={nav == "left" ? "App" : "App_top"} id="Home"
            style={{ fontFamily: nav_btn_font_style, minWidth: "250px" }}
        >

            <Nav // Both Navigators
                active_menu={active_menu}
                setActiveMenu={setActiveMenu}
                top_nav={top_nav} toggleTopNav={toggleTopNav}
                nav={nav}
                setNav={setNav}
                handleSetting={handleSetting}
                handleNavClickScroll={handleNavClickScroll}
                Top={Top}
                show_triple_bar={y_scroll > 40 ? false : true}
                y_scroll={y_scroll}
                pages={pages}
                is_mobile1={is_mobile1}
                bg_transition_duration={bg_transition_duration}
                is_mobile2={is_mobile2}
                is_mobile3={is_mobile3}
                is_tablet1={is_tablet1}
                is_tablet2={is_tablet2}
                is_high_pc1={is_high_pc1}
                side_setting_off={side_setting_off}
                is_mid_high_pc1={is_mid_high_pc1}
                curr_route_page={curr_route_page}
                contactClickRef={contactClickRef}
                measure={measure}
                n_mode={n_mode}
                reactIconRef_M={reactIconRef_M}
                navBtnReff_M={navBtnReff_M}
                show_blog={show_blog}
                setBlogVisibility={setBlogVisibility}
                blog_menus={blog_menus}
                BG={BG}
                text_color={text_color}
                BG_BLOG={BG_BLOG}
                text_color_blog={text_color_blog}
            />


            {/* ================================== IF NO SIDE-BAR */}
            {nav !== "left" &&
                <Routes>
                    <Route path='/'
                        element={
                            <>
                                <Home
                                    nav={nav}
                                    setting_modal={setting_modal}
                                    text_color={text_color}
                                    colors={colors}
                                    toggleTopNav={toggleTopNav}
                                    theme={theme}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    intro_name_font_style={intro_name_font_style}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_low_pc1={is_low_pc1}
                                    is_high_pc1={is_high_pc1}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    n_mode={n_mode}
                                    handleNavClickScroll={handleNavClickScroll}
                                    measure={measure}
                                    styles={styles}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}

                                />

                                <About
                                    nav={nav}
                                    setting_modal={setting_modal}
                                    text_color={text_color}
                                    colors={colors}
                                    toggleTopNav={toggleTopNav}
                                    theme={theme}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    intro_name_font_style={intro_name_font_style}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_low_pc1={is_low_pc1}
                                    is_high_pc1={is_high_pc1}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    n_mode={n_mode}
                                    handleNavClickScroll={handleNavClickScroll}
                                    pc_cards_fontsize={pc_cards_fontsize}
                                    fontSize={fontSize}
                                    measure={measure}
                                    BG={BG}
                                    card_text_style={card_text_style}

                                    handleCardsHorizontalScroll_M={handleCardsHorizontalScroll_M}
                                    handleCardVerticalScroll_Pc={handleCardVerticalScroll_Pc}
                                />

                                <Skills
                                    nav={nav}
                                    setting_modal={setting_modal}
                                    text_color={text_color}
                                    colors={colors}
                                    theme={theme}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    active_menu={active_menu}
                                    pages={pages}
                                    BG={BG}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_low_pc1={is_low_pc1}
                                    is_high_pc1={is_high_pc1}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    n_mode={n_mode}
                                    measure={measure}

                                />

                                <Work
                                    n_mode={n_mode}
                                    theme={theme}
                                    colors={colors}
                                    active_menu={active_menu}
                                    setActiveMenu={setActiveMenu}
                                    top_nav={top_nav} toggleTopNav={toggleTopNav}
                                    nav={nav}
                                    setNav={setNav}
                                    handleSetting={handleSetting}
                                    handleNavClickScroll={handleNavClickScroll}
                                    Top={Top}
                                    show_triple_bar={y_scroll > 40 ? false : true}
                                    y_scroll={y_scroll}
                                    pages={pages}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_high_pc1={is_high_pc1}
                                    side_setting_off={side_setting_off}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    text_color={text_color}
                                    BG={BG}
                                    contactClickRef={contactClickRef}
                                    curr_route_page={curr_route_page}
                                    previousRoutePage={previousRoutePage}
                                    work_btn_visibility_m={work_btn_visibility_m}
                                    pc_cards_fontsize={pc_cards_fontsize}
                                    fontSize={fontSize}
                                    measure={measure}
                                    card_text_style={card_text_style}

                                    handleCardsHorizontalScroll_M={handleCardsHorizontalScroll_M}
                                    handleCardVerticalScroll_Pc={handleCardVerticalScroll_Pc}
                                />

                                <Contact
                                    n_mode={n_mode}
                                    theme={theme}
                                    colors={colors}
                                    active_menu={active_menu}
                                    setActiveMenu={setActiveMenu}
                                    top_nav={top_nav} toggleTopNav={toggleTopNav}
                                    nav={nav}
                                    setNav={setNav}
                                    handleSetting={handleSetting}
                                    handleNavClickScroll={handleNavClickScroll}
                                    Top={Top}
                                    show_triple_bar={y_scroll > 40 ? false : true}
                                    y_scroll={y_scroll}
                                    pages={pages}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_high_pc1={is_high_pc1}
                                    side_setting_off={side_setting_off}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    text_color={text_color}
                                    BG={BG}
                                    contactClickRef={contactClickRef}
                                    curr_route_page={curr_route_page}
                                    measure={measure}

                                />
                            </>
                        }
                    />

                    <Route path='/blog'
                        element={
                            <>
                                <BlogIndex
                                    active_menu={active_menu}
                                    setActiveMenu={setActiveMenu}
                                    is_mobile1={is_mobile1}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_high_pc1={is_high_pc1}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    styles={styles}
                                    measure={measure}
                                    blog_cards={blog_highLights}
                                    text_color={text_color}
                                    setBlogVisibility={setBlogVisibility}
                                />

                                <Contact
                                    n_mode={n_mode}
                                    theme={theme}
                                    colors={colors}
                                    active_menu={active_menu}
                                    setActiveMenu={setActiveMenu}
                                    top_nav={top_nav} toggleTopNav={toggleTopNav}
                                    nav={nav}
                                    setNav={setNav}
                                    handleSetting={handleSetting}
                                    handleNavClickScroll={handleNavClickScroll}
                                    Top={Top}
                                    show_triple_bar={y_scroll > 40 ? false : true}
                                    y_scroll={y_scroll}
                                    pages={pages}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_high_pc1={is_high_pc1}
                                    side_setting_off={side_setting_off}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    text_color={text_color}
                                    BG={BG}
                                    contactClickRef={contactClickRef}
                                    curr_route_page={curr_route_page}
                                    measure={measure}

                                />
                            </>
                        }
                    />

                    <Route path='/blog/:blogUrl'
                        element={
                            <>
                                <Blog
                                    BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure}
                                    styles={styles} blogs={blog_highLights} show_blog={show_blog} setBlogVisibility={setBlogVisibility}
                                />

                                <Contact
                                    n_mode={n_mode}
                                    theme={theme}
                                    colors={colors}
                                    active_menu={active_menu}
                                    setActiveMenu={setActiveMenu}
                                    top_nav={top_nav} toggleTopNav={toggleTopNav}
                                    nav={nav}
                                    setNav={setNav}
                                    handleSetting={handleSetting}
                                    handleNavClickScroll={handleNavClickScroll}
                                    Top={Top}
                                    show_triple_bar={y_scroll > 40 ? false : true}
                                    y_scroll={y_scroll}
                                    pages={pages}
                                    is_mobile1={is_mobile1}
                                    bg_transition_duration={bg_transition_duration}
                                    is_mobile2={is_mobile2}
                                    is_mobile3={is_mobile3}
                                    is_tablet1={is_tablet1}
                                    is_tablet2={is_tablet2}
                                    is_high_pc1={is_high_pc1}
                                    side_setting_off={side_setting_off}
                                    is_mid_high_pc1={is_mid_high_pc1}
                                    text_color={text_color}
                                    BG={BG}
                                    contactClickRef={contactClickRef}
                                    curr_route_page={curr_route_page}
                                    measure={measure}

                                />
                            </>
                        }
                    />

                    <Route path='/blog/ampmin2--/login'
                        element={
                            <Lgn
                                BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure}
                                styles={styles}
                            />
                        }
                    />
                    <Route path='/blog/ampmin2--/dash'
                        element={
                            <ProtectedRoute>
                                <Amp
                                    BG={BG} is_mobile1={is_mobile1} is_mobile3={is_mobile3} text_color={text_color} measure={measure}
                                    styles={styles}
                                />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            }

            {/* ==================================IF SIDE-BAR */}
            {nav == "left" && <Routes>
                <Route path="/" element={

                    <Home
                        nav={nav}
                        setting_modal={setting_modal}
                        text_color={text_color}
                        colors={colors}
                        toggleTopNav={toggleTopNav}
                        theme={theme}
                        is_mobile1={is_mobile1}
                        bg_transition_duration={bg_transition_duration}
                        is_mobile2={is_mobile2}
                        is_mobile3={is_mobile3}
                        intro_name_font_style={intro_name_font_style}
                        is_tablet1={is_tablet1}
                        is_tablet2={is_tablet2}
                        is_low_pc1={is_low_pc1}
                        is_high_pc1={is_high_pc1}
                        is_mid_high_pc1={is_mid_high_pc1}
                        n_mode={n_mode}
                        handleNavClickScroll={handleNavClickScroll}
                        measure={measure}
                        styles={styles}

                    />} />
                <Route path='/Skills' element={<Skills
                    nav={nav}
                    setting_modal={setting_modal}
                    text_color={text_color}
                    colors={colors}
                    theme={theme}
                    is_mobile1={is_mobile1}
                    bg_transition_duration={bg_transition_duration}
                    is_mobile2={is_mobile2}
                    is_mobile3={is_mobile3}
                    active_menu={active_menu}
                    pages={pages}
                    BG={BG}
                    is_tablet1={is_tablet1}
                    is_tablet2={is_tablet2}
                    is_low_pc1={is_low_pc1}
                    is_high_pc1={is_high_pc1}
                    is_mid_high_pc1={is_mid_high_pc1}
                    n_mode={n_mode}
                    measure={measure}

                />} />
                {/* <Route path='/Experience' element={<Experience />} /> */}
                <Route path='/About' element={<About
                    nav={nav}
                    setting_modal={setting_modal}
                    text_color={text_color}
                    colors={colors}
                    toggleTopNav={toggleTopNav}
                    theme={theme}
                    is_mobile1={is_mobile1}
                    bg_transition_duration={bg_transition_duration}
                    is_mobile2={is_mobile2}
                    is_mobile3={is_mobile3}
                    intro_name_font_style={intro_name_font_style}
                    is_tablet1={is_tablet1}
                    is_tablet2={is_tablet2}
                    is_low_pc1={is_low_pc1}
                    is_high_pc1={is_high_pc1}
                    is_mid_high_pc1={is_mid_high_pc1}
                    n_mode={n_mode}
                    handleNavClickScroll={handleNavClickScroll}
                    pc_cards_fontsize={pc_cards_fontsize}
                    fontSize={fontSize}
                    measure={measure}
                    BG={BG}
                    card_text_style={card_text_style}

                    handleCardsHorizontalScroll_M={handleCardsHorizontalScroll_M}
                    handleCardVerticalScroll_Pc={handleCardVerticalScroll_Pc}
                />} />
                <Route path='/Work' element={<Work
                    n_mode={n_mode}
                    theme={theme}
                    colors={colors}
                    active_menu={active_menu}
                    setActiveMenu={setActiveMenu}
                    top_nav={top_nav} toggleTopNav={toggleTopNav}
                    nav={nav}
                    setNav={setNav}
                    handleSetting={handleSetting}
                    handleNavClickScroll={handleNavClickScroll}
                    Top={Top}
                    show_triple_bar={y_scroll > 40 ? false : true}
                    y_scroll={y_scroll}
                    pages={pages}
                    is_mobile1={is_mobile1}
                    bg_transition_duration={bg_transition_duration}
                    is_mobile2={is_mobile2}
                    is_mobile3={is_mobile3}
                    is_tablet1={is_tablet1}
                    is_tablet2={is_tablet2}
                    is_high_pc1={is_high_pc1}
                    side_setting_off={side_setting_off}
                    is_mid_high_pc1={is_mid_high_pc1}
                    text_color={text_color}
                    BG={BG}
                    contactClickRef={contactClickRef}
                    curr_route_page={curr_route_page}
                    previousRoutePage={previousRoutePage}
                    work_btn_visibility_m={work_btn_visibility_m}
                    pc_cards_fontsize={pc_cards_fontsize}
                    fontSize={fontSize}
                    measure={measure}
                    card_text_style={card_text_style}

                    handleCardsHorizontalScroll_M={handleCardsHorizontalScroll_M}
                    handleCardVerticalScroll_Pc={handleCardVerticalScroll_Pc}
                />} />
                <Route path='/Contact' element={<Contact
                    n_mode={n_mode}
                    theme={theme}
                    colors={colors}
                    active_menu={active_menu}
                    setActiveMenu={setActiveMenu}
                    top_nav={top_nav} toggleTopNav={toggleTopNav}
                    nav={nav}
                    setNav={setNav}
                    handleSetting={handleSetting}
                    handleNavClickScroll={handleNavClickScroll}
                    Top={Top}
                    show_triple_bar={y_scroll > 40 ? false : true}
                    y_scroll={y_scroll}
                    pages={pages}
                    is_mobile1={is_mobile1}
                    bg_transition_duration={bg_transition_duration}
                    is_mobile2={is_mobile2}
                    is_mobile3={is_mobile3}
                    is_tablet1={is_tablet1}
                    is_tablet2={is_tablet2}
                    is_high_pc1={is_high_pc1}
                    side_setting_off={side_setting_off}
                    is_mid_high_pc1={is_mid_high_pc1}
                    text_color={text_color}
                    BG={BG}
                    contactClickRef={contactClickRef}
                    curr_route_page={curr_route_page}
                    measure={measure}

                />} />
            </Routes>}
        </div>


        {!is_mobile1 && // theme change setting-bar for PCs
            <CSSTransition
                in={setting_modal} timeout={500}
                classNames="setting_modal"
                unmountOnExit
            // onExit={false} 
            >
                <div className='setting_modal' style={{
                    background: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "fit-content",
                    height: "fit-content",
                    position: "absolute",
                    top: "9vh",
                    right: "5vh",
                    zIndex: 2,
                }}>
                    <div style={{ height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", }}>
                        <div style={{
                            position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center",
                            height: "90%", width: "100%",
                        }}>
                            {<div style={{
                                position: "absolute", display: "flex", alignItems: "center", justifyContent: "space-evenly",
                                width: "100%", borderRadius: "2.5vh", background: text_color, opacity: 0.2,
                                height: '16vh', padding: "0 2vh 0 2vh"
                            }}></div>}
                            <div style={{
                                zIndex: 1, display: "flex", alignItems: "center", justifyContent: "space-evenly", height: '8vh',
                                width: "100%", borderRadius: "2.5vh",
                            }}>
                                {
                                    Object.keys(colors).map((thme, id) => (
                                        <div key={id} style={{
                                            display: "flex", alignItems: "center", justifyContent: "center", width: "25%",
                                            cursor: "pointer", marginRight: id == Object.keys(colors).length - 1 ? "" : "1.5vh"
                                        }}
                                            onClick={() => { theme !== thme ? handleThemeChange(thme) : handleDayNight() }}
                                            title={theme == thme ? nav_tooltip_messages.selected_thm : nav_tooltip_messages.use_thm}
                                        >
                                            <div style={{ height: `${ball_size}vh`, width: `${ball_size / 2}vh`, borderRadius: `${ball_size / 2}vh 0px 0px ${ball_size / 2}vh`, background: n_mode ? colors[thme].bg_night : colors[thme].bg_day }}></div>
                                            <div style={{
                                                height: `${ball_size}vh`, width: `${ball_size / 2}vh`, borderRadius: `0px ${ball_size / 2}vh ${ball_size / 2}vh 0px`,
                                                background: (!n_mode && thme == "thm3") ? colors[thme].clr4 : colors[thme].clr1
                                            }}></div>
                                        </div>
                                    )
                                    )
                                }
                            </div>

                            <div style={{
                                zIndex: 1, display: "flex", alignItems: "center", justifyContent: "space-evenly", height: '8vh',
                                width: "100%", borderRadius: "2.5vh",
                            }}>
                                {
                                    Object.keys(colors).map((thme, id) => (
                                        <div key={id} style={{
                                            display: "flex", alignItems: "center", justifyContent: "center", width: "25%",
                                            cursor: "pointer",
                                        }}
                                            onClick={() => { handleChangeFontStyles(`f_comb${id + 1}`) }}
                                            title={(active_font_comb.substr(active_font_comb.length - 1,) * 1 == id + 1) ? nav_tooltip_messages.selected_font : nav_tooltip_messages.use_font}
                                        >
                                            <div style={{
                                                ...styles.flex_common2,
                                                height: `${ball_size}vh`, width: `${ball_size}vh`,
                                                fontSize: `${measure * 2.5}vh`,
                                                borderRadius: `${ball_size / 6}vh`,
                                                fontFamily: font_combinations[`f_comb${id + 1}`].regular_text_style,
                                                // background: active_font_comb.substr(active_font_comb.length - 1,) * 1 == id + 1 ? text_color : BG,
                                                color: !(active_font_comb.substr(active_font_comb.length - 1,) * 1 == id + 1) ? !n_mode ? clr5 : theme == "thm1" && !n_mode ? clr2 : text_color : n_mode ? clr5 : clr2,
                                            }}>
                                                A/z
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                            </div>
                            <div style={{
                                display: "flex", alignItems: "flex-end", justifyContent: "space-between",
                                alignItems: "flex-end", height: "30%", width: is_mobile1 ? "40%" : "20%", marginTop: "1.5vh"
                            }}>
                                {n_mode ? <div style={{ ...styles.flex_common2 }}><IoIosSunny color={!n_mode && (theme == "thm1" || theme == "thm3") && !curr_route_page ? clr6 : text_color} size={setting_icons_size} onClick={handleDayNight} style={{ cursor: 'pointer' }}
                                    title={nav_tooltip_messages.day_mode}
                                /></div>
                                    : <div style={{ ...styles.flex_common2 }}>
                                        <MdOutlineDarkMode color={!n_mode && (theme == "thm1" || theme == "thm3") && !curr_route_page ? clr6 : text_color} size={setting_icons_size} onClick={handleDayNight} style={{ cursor: 'pointer' }}
                                            title={nav_tooltip_messages.dark_mode}
                                        /></div>}
                                <div style={{ ...styles.flex_common2 }}>
                                    <TfiShiftRightAlt color={!n_mode && (theme == "thm1" || theme == "thm3") && !curr_route_page ? clr6 : text_color} size={setting_icons_size} onClick={() => { setSettingModal(false) }} style={{ cursor: "pointer" }}
                                        title={nav_tooltip_messages.close}
                                    /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>}


        {is_mobile1 && // Theme change setting-bar for phones
            <CSSTransition
                in={setting_modal} timeout={500}
                classNames="setting_modal_p"
                unmountOnExit
            // onExit={""} 
            >
                <div className='setting_modal_p' style={{
                    background: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100vw",
                    // minWidth: "320px",
                    height: "15vh",
                    // maxHeight: "10vh",
                    position: "absolute",
                    bottom: "2vh",
                    zIndex: 2,
                }}>
                    <div style={{
                        height: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
                        minWidth: "300px",
                    }}>
                        <div style={{
                            display: "flex", alignItems: "flex-end", justifyContent: "space-between", alignItems: "flex-end",
                            height: "30%", marginRight: 20, width: "10%",
                        }}>
                            <TfiShiftLeftAlt color={swap_setting_color_M ? BG : text_color} size={`${measure * 4}vh`} onClick={() => { setSettingModal(false) }} style={{ cursor: "pointer" }} />
                        </div>
                        <div style={{
                            position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: "90%",
                            flexDirection: "column", width: "80%",
                        }}>
                            {<div style={{
                                position: "absolute", display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "105%",
                                height: "100%", borderRadius: `${measure * 3}vw`, background: swap_setting_color_M ? BG : text_color, opacity: 0.4,
                            }}></div>}

                            <div style={{
                                zIndex: 1, display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%",
                                height: "50%",
                                borderRadius: `${measure * 2}vw`, padding: `0 ${measure * 3}vw 0 ${measure * 3}vw`,
                            }}>
                                {
                                    Object.keys(colors).map((thme, id) => (
                                        <div key={id} style={{
                                            display: "flex", alignItems: "center", justifyContent: "center", width: "25%", cursor: "pointer",
                                            marginRight: id != 3 ? "3vw" : 0,
                                        }}
                                            onClick={() => { theme !== thme ? handleThemeChange(thme) : handleDayNight() }}
                                        >
                                            <div style={{
                                                height: `${measure * 5.5}vh`, width: `${measure * 5.5 / 2}vh`,
                                                borderRadius: `${measure * 5.5 / 2}vh 0px 0px ${measure * 5.5 / 2}vh`,
                                                // height: "40px", width: "20px", borderRadius: "20px 0px 0px 20px",
                                                background: n_mode ? colors[thme].bg_night : colors[thme].bg_day
                                            }}></div>
                                            <div style={{
                                                height: `${measure * 5.5}vh`, width: `${measure * 5.5 / 2}vh`,
                                                borderRadius: `0px ${measure * 5.5 / 2}vh ${measure * 5.5 / 2}vh 0px`,
                                                // height: "40px", width: "20px", borderRadius: "0px 20px 20px 0px",
                                                background: (!n_mode && thme == "thm3") ? colors[thme].clr4 : colors[thme].clr1
                                            }}></div>
                                        </div>
                                    )
                                    )
                                }
                            </div>

                            <div style={{
                                marginTop: `${measure * 1}vh`,
                                zIndex: 1, display: "flex", alignItems: "center", justifyContent: "space-evenly", width: "100%", height: "25%",
                                borderRadius: `${measure * 2}vw`, padding: `0 ${measure * 3}vw 0 ${measure * 3}vw`,
                            }}>
                                {
                                    Object.keys(colors).map((thme, id) => (
                                        <div key={id} style={{
                                            display: "flex", alignItems: "center", justifyContent: "center", width: "25%", cursor: "pointer",
                                            marginRight: id != 3 ? "3vw" : 0,
                                        }}
                                            onClick={() => { handleChangeFontStyles(`f_comb${id + 1}`) }}
                                        >
                                            <div
                                                style={{
                                                    ...styles.flex_common2,
                                                    height: `${measure * 5.5}vh`, width: `${measure * 5.5 / 2}vh`,
                                                    fontSize: `${measure * 2.5}vh`,
                                                    // borderRadius: `${ball_size / 6}vh`,
                                                    fontFamily: font_combinations[`f_comb${id + 1}`].regular_text_style,
                                                    // background: active_font_comb.substr(active_font_comb.length - 1,) * 1 == id + 1 ? text_color : BG,
                                                    color: !(active_font_comb.substr(active_font_comb.length - 1,) * 1 == id + 1) ? !n_mode ? clr5 : theme == "thm1" && !n_mode ? clr2 : text_color : n_mode ? clr5 : clr2,
                                                }}
                                            >
                                                A/z
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                            </div>

                        </div>
                        <div style={{
                            display: "flex", alignItems: "flex-end", justifyContent: "space-between", alignItems: "flex-end", height: "30%",
                            marginLeft: 20, width: "10%",
                        }}>
                            {n_mode ?
                                <IoIosSunny color={swap_setting_color_M ? BG : text_color} size={`${measure * 4}vh`} onClick={handleDayNight} style={{ cursor: 'pointer' }} />
                                :
                                <IoMoonSharp color={swap_setting_color_M ? BG : text_color} size={`${measure * 4}vh`} onClick={handleDayNight} style={{ cursor: 'pointer' }} />
                            }
                        </div>
                    </div>
                </div>
            </CSSTransition>}
    </div>
    )
})

export default LandPage
