import React, { memo, useLayoutEffect, useRef } from 'react'
import './about.css'
import { FaCalendar } from 'react-icons/fa'
import { about_text as about_txt, edu as educ, exp as expr } from '../model/data'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { font_combinations, theme_combinations } from '../../global_store/theme_combinations'

const About = memo(function Abt(props) {

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8, clr9 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]

    let { BG, is_mobile1, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure, card_text_style, } = props
    let dWidth = window.innerWidth
    let dHeight = window.innerHeight
    let styles = {
        flex_common1: { display: "flex", justifyContent: "center", },
        flex_common2: { display: "flex", justifyContent: "center", alignItems: "center" }
    }



    const fontSize = props.fontSize
    const pc_cards_fontsize = props.pc_cards_fontsize //pc

    const about_text = about_txt
    const edu = educ
    const exp = expr
    const about_exp_edu_m = exp.concat(edu)

    const [clicked_About, setClickedAbout] = useState(about_text)
    const [clicked_edu_id, setClieckedEduId] = useState()
    const [clicked_exp_id, setClieckedExpId] = useState()
    const [clicked_id_m, setClieckedId_m] = useState()
    // const [clicked_About_m, setClickedAbout_m] = useState(about_text)

    useLayoutEffect(() => {
        setClickedAbout(about_text)
    }, [is_mobile1])



    const aboutListContentReff_M = useRef({})   //mobile
    const aboutEduListContentReff_Pc = useRef({}) //pc
    const aboutExpListContentReff_Pc = useRef({}) //pc

    const unclicked_card_bg = BG
    const clicked_card_bgg = !n_mode ? text_color : (theme == "thm1" && n_mode) ? BG : text_color
    // const clicked_card_shadow = !n_mode ? clr6 : (theme == "thm1" && n_mode) ? clr5 : clr9
    const clicked_card_shadow = !n_mode ? clr6 : (theme == "thm1" && n_mode) ? clr5 : clr6
    const unclicked_card_shadow = text_color
    const clicked_card_color = !n_mode ? BG : (theme == "thm1" && n_mode) ? text_color : BG
    const unclicked_card_color = text_color

    const handleAboutClick = (el, id, is_pc = false, pc_trait_type) => {
        setClickedAbout(""); setTimeout(() => { setClickedAbout(el); }, 25) // cleaning it first to start , new one from start of line
        if (is_pc) {
            if (pc_trait_type == "edu") {
                setClieckedExpId(undefined); setClieckedEduId(id);
                props.handleCardVerticalScroll_Pc("About", aboutEduListContentReff_Pc, el, id)
            } else {
                setClieckedExpId(id); setClieckedEduId(undefined)
                props.handleCardVerticalScroll_Pc("About", aboutExpListContentReff_Pc, el, id)
            }
        } else {
            setClieckedId_m(id)
            props.handleCardsHorizontalScroll_M("About", aboutListContentReff_M, el, id)
        }
    }


    return (
        <div id="About" style={{ height: "100vh", width: "100vw", wordBreak: "break-word", fontFamily: regular_text_style, color: text_color }}>
            { // PC
                !is_mobile1 &&
                <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "flex-end" }}>
                    <div style={{
                        ...styles.flex_common2, height: "6%", marginBottom: "2vh", width: "100%", fontFamily: font3, fontSize: "3vh",
                        background: BG,
                        transition: `background ${props.bg_transition_duration}ms`,
                        zIndex: 1,
                    }}>
                        <div style={{ ...styles.flex_common2, alignItems: "flex-end", height: "100%", width: "40%" }}>About</div>
                        <div style={{ ...styles.flex_common2, width: "60%", height: "100%" }}>
                            <div style={{ ...styles.flex_common2, alignItems: "flex-end", width: "50%", height: "100%" }}>Education</div>
                            <div style={{ ...styles.flex_common2, alignItems: "flex-end", width: "50%", height: "100%" }}>Experience</div>
                        </div>
                    </div>
                    <div style={{ ...styles.flex_common2, height: "83%" }}>
                        <div
                            style={{ ...styles.flex_common2, height: "100%", width: "40%" }}
                        >
                            <div
                                onClick={() => { let temp = { ...clicked_About }; setClickedAbout(""); setTimeout(() => { setClickedAbout(temp); }, 20) }}
                                style={{
                                    display: "flex", flexDirection: "column",
                                    height: "80%", width: "90%", overflowY: "auto", color: text_color,
                                    fontFamily: regular_text_style, fontSize: fontSize
                                }}><div className={"info_text"}
                                    style={{ fontSize: fontSize, }}
                                >
                                    &nbsp;&nbsp;&nbsp;&nbsp;{clicked_About.info}
                                </div>
                            </div>
                        </div>

                        <div
                            style={{ ...styles.flex_common2, width: "60%", maxHeight: "100%", fontSize: pc_cards_fontsize }}
                        >
                            <div style={{
                                ...styles.flex_common1, height: "80vh", width: "45%", overflowY: "auto",
                            }}>
                                <div
                                    style={{
                                        width: "90%", height: "fit-content",
                                    }}
                                >
                                    {edu.map((el, id) =>
                                        <div
                                            ref={ref => aboutEduListContentReff_Pc.current[id] = ref}
                                            onClick={() => { handleAboutClick(el, id, true, "edu") }}
                                            style={{ ...styles.flex_common2, width: "100%", height: "40vh", padding: "2.7vh 0 2.7vh 0", }}
                                            key={id}
                                        >
                                            <div
                                                style={{
                                                    ...styles.flex_common2, cursor: "pointer",
                                                    height: "100%", width: "100%", borderRadius: "3vh",
                                                    boxShadow: `0 0 1.2vh .6vh ${id != clicked_edu_id ? clicked_card_shadow : unclicked_card_shadow}`,
                                                    // background: id == clicked_edu_id ? clicked_card_bgg : unclicked_card_bg,
                                                    // transition: `background ${props.bg_transition_duration}ms`,
                                                    // color: id == clicked_edu_id ? clicked_card_color : unclicked_card_color,
                                                }}
                                            >
                                                <div style={{
                                                    ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                    width: "85%"
                                                }}>
                                                    <div style={{ ...card_text_style, height: "25%" }}>
                                                        <FaCalendar /> {el.range}
                                                    </div>
                                                    <div style={{ ...card_text_style, height: "25%" }}>{el.name}</div>
                                                    <div style={{ ...card_text_style }}>{el.info}</div>
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>

                            <div style={{ ...styles.flex_common1, height: "80vh", width: "45%", overflowY: "auto" }}>

                                <div
                                    style={{
                                        width: "90%", height: "fit-content",
                                    }}
                                >
                                    {exp.map((el, id) =>
                                        <div
                                            style={{ ...styles.flex_common2, width: "100%", height: "40vh", padding: "2.7vh 0 2.7vh 0", }}
                                            ref={ref => aboutExpListContentReff_Pc.current[id] = ref}
                                            onClick={() => { handleAboutClick(el, id, true, "exp") }}
                                            key={id}
                                        >
                                            <div
                                                style={{
                                                    ...styles.flex_common2, cursor: "pointer",
                                                    height: "40vh", width: "100%",
                                                    borderRadius: "3vh", boxShadow: `0 0 1.2vh .6vh ${id != clicked_exp_id ? clicked_card_shadow : unclicked_card_shadow}`,
                                                    // background: id == clicked_exp_id ? clicked_card_bgg : unclicked_card_bg,
                                                    //  transition: `background ${props.bg_transition_duration}ms`,
                                                    // color: id == clicked_exp_id ? clicked_card_color : unclicked_card_color,
                                                }}
                                            >
                                                <div style={{
                                                    ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                    width: "85%"
                                                }}>
                                                    <div style={{ ...card_text_style, height: "20%" }}>
                                                        <FaCalendar /> {el.range}
                                                    </div>
                                                    <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                    <div style={{ ...card_text_style, height: "20%" }}>{el.desg}</div>
                                                    <div style={{ ...card_text_style }}>{el.info}</div>
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }

            {is_mobile1 && //======================================================== MOBILE
                <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column", justifyContent: "flex-end", }}>
                    {/* <div style={{
                        ...styles.flex_common2, height: "20%", width: "100%", fontFamily: font3, fontSize: "3vh",
                    }}>
                        <div style={{ ...styles.flex_common2, alignItems: "flex-end", height: "100%", width: "40%" }}>About</div>
                        <div style={{ ...styles.flex_common2, width: "60%", height: "100%" }}>
                            <div style={{ ...styles.flex_common2, alignItems: "flex-end", width: "50%", height: "100%" }}>Education</div>
                            <div style={{ ...styles.flex_common2, alignItems: "flex-end", width: "50%", height: "100%" }}>Experience</div>
                        </div>
                    </div> */}
                    <div style={{ ...styles.flex_common2, justifyContent: "space-between", flexDirection: "column", height: "95%", overflowY: "auto", }}>
                        <div
                            style={{ ...styles.flex_common2, width: "90%", height: "45%", paddingTop: "5vh" }}
                        >
                            <div
                                onClick={() => { let temp = { ...clicked_About }; setClickedAbout(""); setTimeout(() => { setClickedAbout(temp); }, 20) }}
                                style={{
                                    display: "flex", flexDirection: "column",
                                    height: "90%", width: "90%", overflowY: "auto", color: text_color,
                                    fontFamily: regular_text_style, fontSize: fontSize,
                                }}><div
                                    className={"info_text_m"}
                                    style={{ display: "inline", fontSize: "4vh" }}
                                >
                                    {clicked_About.info}
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex", width: "90vw", height: "40%", overflowY: "auto", overflowX: "auto", paddingLeft: "10vw",
                                marginBottom: "5vh",
                            }}
                        >
                            <div style={{}}>

                                <div
                                    style={{
                                        height: "fit-content", display: "flex", justifyContent: "flex-start", padding: "2vh 0 2vh 0",

                                    }}
                                >
                                    {about_exp_edu_m.map((el, id) =>
                                        <div ref={ref => aboutListContentReff_M.current[id] = ref} key={id}
                                            onClick={() => {
                                                handleAboutClick(el, id, false)
                                            }}
                                            style={{
                                                ...styles.flex_common2, marginRight: `${measure * 6.5}vh`, cursor: "pointer",
                                                height: "33vh", width: "33vh",
                                                borderRadius: "3vh",
                                                boxShadow: `0 0 10px 5px ${id != clicked_id_m ? clicked_card_shadow : unclicked_card_shadow}`,
                                                // background: id == clicked_id_m ? clicked_card_bgg : unclicked_card_bg,
                                                // transition: `background ${props.bg_transition_duration}ms`,
                                                // color: id == clicked_id_m ? clicked_card_color : unclicked_card_color,
                                            }}
                                        >
                                            <div style={{
                                                ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                width: "85%"
                                            }}>
                                                <div style={{
                                                    height: "20%", display: "flex", justifyContent: "space-between", position: "relative",
                                                    fontSize: `${measure * 2}vh`, width: "100%"
                                                }}>
                                                    <div><FaCalendar /> {el.range}</div>
                                                    <div style={{}}> {el.type == "exp" ? "Experience" : "Education"}</div>
                                                </div>
                                                <div style={{ ...card_text_style, height: "20%" }}>{el.name}</div>
                                                <div style={{ ...card_text_style, height: "20%" }}>{el.desg}</div>
                                                <div style={{ ...card_text_style }}>{el.info}</div>
                                            </div>

                                        </div>
                                    )}

                                </div>
                            </div>


                            {/* <div style={{ ...styles.flex_common1, height: "65%", width: "90vw", overflowY: "auto", }}>
                                <div
                                    style={{
                                        width: "90%", height: "fit-content", display: "flex", flexDirection: "column", alignItems: "center",
                                    }}
                                >
                                    {edu.map((el, id) =>
                                        <div
                                            style={{
                                                ...styles.flex_common2,
                                                height: "40vh", width: "93%", borderRadius: "3vh", marginTop: "6vh",
                                                boxShadow: `0 0 10px 5px ${text_color}`, marginBottom: id == 2 ? "6vh" : ""
                                            }}
                                        >
                                            <div style={{
                                                ...styles.flex_common2, alignItems: "flex-start", flexDirection: "column", height: "85%",
                                                width: "85%"
                                            }}>
                                                <div style={{ height: "25%" }}>
                                                    <FaCalendar /> {el.range}
                                                </div>
                                                <div style={{ height: "25%" }}>{el.name}</div>
                                                <div style={{ height: "50%" }}>{el.info}</div>
                                            </div>

                                        </div>
                                    )}

                                </div>
                            </div> */}
                        </div>
                    </div>

                </div>
            }
        </div>
    )
})

export default About

