import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme_combinations, font_combinations } from '../../../global_store/theme_combinations';
import { useState } from 'react';
import axios from 'axios';
import { ActionSetAmp } from '../../../global_store/amp_reducer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { base_url } from '../../../config/env';


const Amp = memo(function Amp(props) {
    const [user_name, setUname] = useState("")
    const [user_type, setUserType] = useState("")
    const [user_password, setPass] = useState("")
    const [user_email, setUserEmail] = useState("")
    const [user_phone, setUserPhone] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const amp = useSelector((state) => state.AllReducerCombined.ampReducer.amp)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    let { BG, is_mobile1, is_mobile3, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure, styles,
        blog_cards, idd } = props

    useEffect(() => {
        isValidSession()
    }, [])

    const isValidSession = () => {
        const token = localStorage.getItem("token")
        if (token && amp) {
            const headers = { "auth_token": token, "Content-Type": "application/json", Accept: "application/json" };
            const base_urll = base_url
            const url = `${base_urll}/login/chk`
            axios.get(url, { headers: headers }).then((res) => {
                if (res.status == 200) {
                    dispatch(ActionSetAmp({ amp: 1 }))
                    navigate({ pathname: "/Blog/ampmin2--/dash" })
                } else {
                    dispatch(ActionSetAmp({ amp: 0 }))
                    localStorage.setItem("token", "")

                }
            }

            ).catch((err) => {
                console.log({ err });
                dispatch(ActionSetAmp({ amp: 0 }))
                localStorage.setItem("token", "")
            })
        }
    }

    const Login = async () => {
        // const headers = {"auth_token": localStorage.getItem("token"), "Content-Type": "application/json",  Accept: "application/json",};
        const base_urll = base_url
        let url = `${base_urll}/login/`
        const body = {
            user_name: user_name.trim(), user_type: user_type.trim(), user_phone: user_phone.trim(),
            user_password: user_password.trim(), user_email: user_email.trim()
        }
        // axios.post(url, body).then((res) => { console.log(res) }).catch((err) => { alert(err.response.message) })
        // axios.get(url, { headers: headers }).then((res) => console.log({ res })).catch((err) => console.log({ err }))
        axios.post(url, body).then(async (res) => {
            if (res.status == 200) {
                if (res.data.token) {
                    dispatch(ActionSetAmp({ amp: 1 }))
                    localStorage.setItem("token", res.data.token)
                    navigate({ pathname: "/Blog/ampmin2--/dash" })
                } else {
                    dispatch(ActionSetAmp({ amp: 0 }))
                    localStorage.setItem("token", "")

                }
            }
        }).catch((err) => {
            console.log({ err });
            dispatch(ActionSetAmp({ amp: 0 }))
            localStorage.setItem("token", "")
        })
    }

    const handleSubmit = () => {
        if (user_name && user_type && user_password && user_email) {
            Login()
        } else {
            alert("Fill it correct !")
        }
    }

    return (
        <>
            <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", color: text_color }}>
                Login&nbsp;&nbsp;&nbsp;&nbsp;
                <div style={{ height: "30%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <input value={user_name} placeholder='Enter user_name' onChange={(change) => { setUname(change.target.value) }} />
                    <input value={user_type} style={{ marginTop: "1vh" }} placeholder='Enter' onChange={(change) => { setUserType(change.target.value) }} />
                    <input value={user_email} style={{ marginTop: "1vh" }} placeholder='Enter' onChange={(change) => { setUserEmail(change.target.value) }} />
                    <input value={user_password} style={{ marginTop: "1vh" }} placeholder='Enter' onChange={(change) => { setPass(change.target.value) }} />
                    <input value={user_phone} style={{ marginTop: "1vh" }} placeholder='Enter' onChange={(change) => { setUserPhone(change.target.value) }} />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp; <button
                    onClick={handleSubmit}
                >Submit</button>
            </div>
        </>
    )
})

export default Amp
