import ProgressBar from "@ramonak/react-progress-bar";
import React, { memo } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiOutlineApi } from "react-icons/ai";
import { BsGit } from "react-icons/bs";
import { FaAppStoreIos, FaAws, } from "react-icons/fa";
import { IoLogoFreebsdDevil } from "react-icons/io";
import {
    SiAndroidstudio,
    SiExpress,
    SiFirebase, SiHackerrank,
    SiJavascript,
    SiJest,
    SiMongodb,
    SiNodedotjs,
    SiPython, SiReduxsaga,
    SiSemanticuireact,
    SiXcode,
} from "react-icons/si";
import {
    TbBrandCss3,
    TbBrandDjango,
    TbBrandGithub,
    TbBrandHtml5, TbBrandMysql,
    TbBrandNextjs,
    TbBrandReactNative,
    TbBrandRedux,
    TbBrandTypescript,
    TbBrandUbuntu,
    TbBrandYoutube,
    TbWebhook
} from "react-icons/tb";
import { useSelector } from "react-redux";
import { CSSTransition } from 'react-transition-group';
import { font_combinations, theme_combinations } from "../../global_store/theme_combinations";
import "./skills.css";




const Skills = memo(function Skills(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]

    let { BG, is_mobile1, is_mobile3, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure } = props
    let dWidth = window.innerWidth
    let dHeight = window.innerHeight
    let styles = {
        flex_common1: { display: "flex", justifyContent: "center", },
        flex_common2: { display: "flex", justifyContent: "center", alignItems: "center" }
    }

    let skills = [{ skill_name: "React", skilled: 80 }, { skill_name: "ReactNative", skilled: 90 },
    { skill_name: "Node", skilled: 50 }, { skill_name: "Express", skilled: 60 }, { skill_name: "AWS", skilled: 50 },
    { skill_name: "MySQL", skilled: 50 }, { skill_name: "Django", skilled: 50 }, { skill_name: "python", skilled: 80 }, { skill_name: "Javascript", skilled: 90 },
    ]
    const decideIconCircle = (el) => {
        let color = text_color; let size = is_mobile1 ? 30 : is_tablet2 ? "5vh" : is_tablet1 ? "8vh" : "11vh"
        if (el.skill_name == "React") {
            return <SiSemanticuireact color={color} size={size} />
        }
        if (el.skill_name == "ReactNative") {
            return <TbBrandReactNative color={color} size={size} />
        }
        if (el.skill_name == "Node") {
            return <SiNodedotjs color={color} size={size} />
        }
        if (el.skill_name == "AWS") {
            return <FaAws color={color} size={size} />
        }
        if (el.skill_name == "Express") {
            return <SiExpress color={color} size={size} />
        }
        if (el.skill_name == "Django") {
            return <TbBrandDjango color={color} size={size} />
        }
        if (el.skill_name == "MySQL") {
            return <TbBrandMysql color={color} size={size} />
        }
        if (el.skill_name == "Javascript") {
            return <SiJavascript color={color} size={size} />
        }
        if (el.skill_name == "python") {
            return <SiPython color={color} size={size} />
        }

    }


    let all_soft_skills = [
        { skill_name: "REST-API", skilled: 70 }, { skill_name: "Redux", skilled: 100 }, { skill_name: "Saga", skilled: 95 },
        { skill_name: "React-Hooks", skilled: 90 }, { skill_name: "MongoDb", skilled: 50 }, { skill_name: "Linux", skilled: 70 },
        { skill_name: "Firebase-FCM", skilled: 90 }, { skill_name: "HTML5", skilled: 80 },
        { skill_name: "CSS3", skilled: 90 }, { skill_name: "Next.js", skilled: 50 }, { skill_name: "Typescript", skilled: 60 },
        { skill_name: "Jest", skilled: 50 }, { skill_name: "Compt-Programming", skilled: 95 }, { skill_name: "Android-Studio", skilled: 90 },
        { skill_name: "IOS", skilled: 80 }, { skill_name: "Xcode", skilled: 90 }, { skill_name: "Logo-Design", skilled: 50 },
        { skill_name: "Thumbnail-Design", skilled: 50 }, { skill_name: "Git", skilled: 90 }, { skill_name: "Github", skilled: 80 },

    ]
    let all_soft_skills1 = all_soft_skills.slice(0, all_soft_skills.length % 2 == 0 ? all_soft_skills.length / 2 : Math.trunc(all_soft_skills.length / 2))  //pc
    let all_soft_skills2 = all_soft_skills.slice((all_soft_skills.length / 2),) //pc
    let pc_skill_bar_height = "2.5vh" //pc

    const decideIcon = (el) => {
        let color = text_color; let size = is_mobile1 ? 23 : "5.5vh"
        if (el.skill_name == "REST-API") {
            return <AiOutlineApi color={color} size={size} />
        }
        if (el.skill_name == "Redux") {
            return <TbBrandRedux color={color} size={size} />
        }
        if (el.skill_name == "Saga") {
            return <SiReduxsaga color={color} size={size} />
        }
        if (el.skill_name == "React-Hooks") {
            return <TbWebhook color={color} size={size} />
        }
        if (el.skill_name == "MongoDb") {
            return <SiMongodb color={color} size={size} />
        }
        if (el.skill_name == "Linux") {
            return <TbBrandUbuntu color={color} size={size} />
        }
        if (el.skill_name == "Firebase-FCM") {
            return <SiFirebase color={color} size={size} />
        }
        if (el.skill_name == "Next.js") {
            return <TbBrandNextjs color={color} size={size} />
        }
        if (el.skill_name == "Typescript") {
            return <TbBrandTypescript color={color} size={size} />
        }
        if (el.skill_name == "Jest") {
            return <SiJest color={color} size={size} />
        }
        if (el.skill_name == "Compt-Programming") {
            return <SiHackerrank color={color} size={size} />
        }
        if (el.skill_name == "HTML5") {
            return <TbBrandHtml5 color={color} size={size} />
        }
        if (el.skill_name == "CSS3") {
            return <TbBrandCss3 color={color} size={size} />
        }
        if (el.skill_name == "Logo-Design") {
            return <IoLogoFreebsdDevil color={color} size={size} />
        }
        if (el.skill_name == "Thumbnail-Design") {
            return <TbBrandYoutube color={color} size={size} />
        }
        if (el.skill_name == "Android-Studio") {
            return <SiAndroidstudio color={color} size={size} />
        }
        if (el.skill_name == "IOS") {
            return <FaAppStoreIos color={color} size={size} />
        }
        if (el.skill_name == "Xcode") {
            return <SiXcode color={color} size={size} />
        }
        if (el.skill_name == "Github") {
            return <TbBrandGithub color={color} size={size} />
        }
        if (el.skill_name == "Git") {
            return <BsGit color={color} size={size} />
        }

    }



    return (
        <div id="Skills" style={{ height: "100vh", }}>
            {!is_mobile1 && <div className='skill_p' style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%", width: "100%", }}> {/* PC */}
                <CSSTransition in={pages[active_menu] == "Skills"} timeout={700} classNames="skill_p_container" unmountOnExit>
                    <div className='skill_p_container'
                        style={{
                            height: "90%", display: "flex", alignItems: "center",
                        }}>

                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "space-between", }}>


                            <div style={{ ...styles.flex_common2, width: "33.33%", }} >
                                <div style={{
                                    display: "flex", alignItems: "center", flexDirection: "column", width: "75%",
                                    minHeight: "80%", maxHeight: "95vh", overflowY: "auto",
                                }}>
                                    {skills.map((el, id) =>
                                        <div key={id} style={{ ...styles.flex_common2, width: "100%", marginTop: "3.5vh", }}>
                                            <div style={{ ...styles.flex_common2, width: "50%", }} >
                                                <div style={{
                                                    ...styles.flex_common2, flexDirection: "column",
                                                    alignItems: "center", width: "90%", marginRight: "2vw",
                                                }}>
                                                    {decideIconCircle(el)}
                                                    <div style={{
                                                        ...styles.flex_common2, fontSize: `${measure * 3}vh`,
                                                        fontFamily: regular_text_style, wordBreak: "break-word", color: text_color,
                                                    }}>
                                                        {el.skill_name}
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ ...styles.flex_common2, justifyContent: "flex-start", width: "50%", }}>
                                                <div style={{
                                                    ...styles.flex_common2,
                                                }}>
                                                    <div style={{
                                                        ...styles.flex_common2, width: "100%", position: "relative", height: "fit-content",
                                                    }}>
                                                        <CircularProgressbarWithChildren
                                                            // percentage={20}
                                                            value={el.skilled}
                                                            background
                                                            styles={{
                                                                // Customize the root svg element
                                                                root: {
                                                                    height: "14.5vh",
                                                                    position: "relative",
                                                                },
                                                                // Customize the path, i.e. the "completed progress"
                                                                path: {
                                                                    // Path color
                                                                    // stroke: `rgba(0, 0, 0, ${el.skilled / 100})`,
                                                                    stroke: `${text_color}`,
                                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                    strokeLinecap: "butt",
                                                                    // Customize transition animation
                                                                    transition: 'stroke-dashoffset 2s ease 0s',
                                                                    // Rotate the path
                                                                    transform: 'rotate(0.5turn)',
                                                                    transformOrigin: 'center center',
                                                                },
                                                                // Customize the circle behind the path, i.e. the "total progress"
                                                                trail: {
                                                                    // Trail color
                                                                    stroke: `${clr7}`,
                                                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                    strokeLinecap: 'butt',
                                                                    // Rotate the trail
                                                                    transform: 'rotate(0.25turn)',
                                                                    transformOrigin: 'center center',
                                                                    // transition: "transform 2s ease",
                                                                },
                                                                // Customize the text
                                                                text: {
                                                                    // Text color
                                                                    fill: '#f88',
                                                                    // Text size
                                                                    fontSize: is_mobile2 ? "2.5vh" : "2.5vh",
                                                                },
                                                                // Customize background - only used when the `background` prop is true
                                                                background: {
                                                                    fill: `${BG}`,
                                                                },
                                                            }}
                                                        >
                                                        </CircularProgressbarWithChildren>
                                                        <div style={{ position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", }}>
                                                            <div style={{ ...styles.flex_common2, flexDirection: "column", width: "100%" }}>
                                                                <div style={{ ...styles.flex_common2, color: text_color, }}>
                                                                    {/* {decideIconCircle(el)} */}
                                                                    <div style={{ display: "inline", fontFamily: regular_text_style, fontSize: is_mobile2 ? "2.5vh" : "2.5vh", paddingLeft: "0.5vw" }}>{el.skilled}%</div>
                                                                </div>
                                                                {/* <div style={{ ...styles.flex_common2, fontSize: is_mobile2 ? "2.5vh" : "2.5vh", wordBreak: "break-word", color: text_color, maxWidth: "50%" }}>
                                                            {el.skill_name} 
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </div>

                            </div>





                            <div style={{ ...styles.flex_common2, width: "33.33%", }} >
                                <div style={{ width: "82%", minHeight: "80%", maxHeight: "95vh", overflowY: "auto", }} >
                                    {all_soft_skills1.map((el, id) =>
                                        <div key={id} style={{ height: "fit-content", marginTop: "3.5vh", }}>
                                            <div style={{ display: "flex", width: "97%", justifyContent: "space-between", marginBottom: "0.5vh" }}>
                                                {decideIcon(el)}
                                                <div style={{ ...styles.flex_common2, color: text_color, fontFamily: regular_text_style, fontSize: `${measure * 2.2}vh`, }}>{`${el.skill_name} `}</div>
                                            </div>
                                            <ProgressBar
                                                completed={el.skilled}
                                                bgColor={text_color}
                                                height={pc_skill_bar_height}
                                                baseBgColor={BG}
                                                customLabel={`${el.skilled}%`}
                                                // borderRadius={"1vh"}
                                                labelSize={`${measure * 1.8}vh`}
                                                labelColor={BG}
                                                animateOnRender={true}
                                                barContainerClassName={`bar_container_p_${theme}${n_mode ? "_n" : "_d"}`}
                                                initCompletedOnAnimation={50}
                                                transitionDuration={"1.5s"}
                                                customLabelStyles={{ fontFamily: regular_text_style }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>





                            <div style={{ ...styles.flex_common2, width: "33.33%", }} >
                                <div style={{ width: "82%", minHeight: "80%", maxHeight: "95vh", overflowY: "auto", }} >
                                    {all_soft_skills2.map((el, id) =>
                                        <div key={id} style={{ height: "fit-content", marginTop: "3.5vh", }}>
                                            <div style={{ display: "flex", width: "97%", justifyContent: "space-between", marginBottom: "0.5vh" }}>
                                                {decideIcon(el)}
                                                <div style={{ ...styles.flex_common2, color: text_color, fontFamily: regular_text_style, fontSize: is_mobile2 ? "20px" : "20px", }}>{`${el.skill_name} `}</div>
                                            </div>
                                            <ProgressBar
                                                completed={el.skilled}
                                                bgColor={text_color}
                                                height={pc_skill_bar_height}
                                                baseBgColor={BG}
                                                customLabel={`${el.skilled}%`}
                                                // borderRadius={"1vh"}
                                                labelSize={"1.8vh"}
                                                labelColor={BG}
                                                animateOnRender={true}
                                                barContainerClassName={`bar_container_p_${theme}${n_mode ? "_n" : "_d"}`}
                                                initCompletedOnAnimation={50}
                                                transitionDuration={"1.5s"}
                                                customLabelStyles={{ fontFamily: regular_text_style }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>}

            {is_mobile1 && <div className='skill_p' style={{ height: "100%", fontFamily: nav_btn_font_style, fontSize: is_mobile2 ? "12px" : "3vw" }}>{/* Phone */}
                <CSSTransition in={pages[active_menu] == "Skills"} timeout={700} classNames="skill_p_container_p" unmountOnExit>
                    <div className='skill_p_container_p'
                        style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                        }}>

                        <div style={{
                            height: "100%", overflowY: "auto", width: "100%", display: "flex", justifyContent: "space-between",
                            alignItems: "center"
                        }}>


                            <div style={{
                                display: "flex", flexDirection: "column", alignItems: "center", minHeight: "80%", maxHeight: "90vh",
                                overflowY: "auto", width: "50%"
                            }} >
                                {skills.map((el, id) =>
                                (
                                    <div key={id} style={{ ...styles.flex_common2, width: "90%", marginTop: "3vh", }}>
                                        <div style={{ ...styles.flex_common2, width: "100%", alignItems: "center", }}>

                                            <div style={{
                                                height: "fit-content", width: "50%", position: "relative", display: "flex", justifyContent: "center",
                                                alignItems: "center",
                                            }}>
                                                <CircularProgressbarWithChildren
                                                    // percentage={20}
                                                    value={el.skilled}
                                                    background
                                                    styles={{
                                                        // Customize the root svg element
                                                        root: {
                                                            height: `${measure * 9.5}vh`,
                                                            position: "relative",
                                                        },
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                            // Path color
                                                            // stroke: `rgba(0, 0, 0, ${el.skilled / 100})`,
                                                            stroke: `${text_color}`,
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: "butt",
                                                            // Customize transition animation
                                                            transition: 'stroke-dashoffset 2s ease 0s',
                                                            // Rotate the path
                                                            transform: 'rotate(0.5turn)',
                                                            transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                            // Trail color
                                                            stroke: `${clr7}`,
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'butt',
                                                            // Rotate the trail
                                                            transform: 'rotate(0.25turn)',
                                                            transformOrigin: 'center center',
                                                            // transition: "transform 2s ease",
                                                        },
                                                        // Customize the text
                                                        text: {
                                                            // Text color
                                                            fill: '#f88',
                                                            // Text size
                                                            fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                            fill: `${BG}`,
                                                        },
                                                    }}
                                                >
                                                </CircularProgressbarWithChildren>
                                                <div style={{
                                                    position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center",
                                                    justifyContent: "center",
                                                }}>
                                                    <div style={{ ...styles.flex_common2, flexDirection: "column", width: "100%" }}>
                                                        {/* <div style={{ ...styles.flex_common2, color: text_color, }}>{decideIconCircle(el)} */}
                                                        <div style={{ color: text_color, display: "inline", fontSize: (is_mobile1 && !is_mobile3) ? `${measure * 3}vw` : is_mobile2 ? "12px" : `${measure * 4}vw`, paddingLeft: "0.5vw" }}>{el.skilled}%</div>
                                                        {/* </div> */}
                                                        {/* <div style={{
                                                            ...styles.flex_common2, wordBreak: "break-word", color: text_color,
                                                            fontFamily: nav_btn_font_style, fontSize: is_mobile2 ? "12px" : "2.5vw", maxWidth: "50%"
                                                        }}>{el.skill_name} </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ ...styles.flex_common2, width: "50%", }} >
                                                <div style={{
                                                    ...styles.flex_common2, flexDirection: "column",
                                                    alignItems: "center", width: "90%", marginRight: "2vw",
                                                }}>
                                                    {decideIconCircle(el)}
                                                    <div style={{
                                                        ...styles.flex_common2,
                                                        wordBreak: "break-word", color: text_color, fontSize: is_mobile2 ? "12px" : "3.5vw", marginTop: "0.5vh",
                                                    }}>
                                                        {el.skill_name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                )}

                            </div>





                            <div style={{ ...styles.flex_common2, height: "100%", width: "50%", }} >
                                <div style={{
                                    display: "flex", flexDirection: "column", width: "86%", minHeight: "80%",
                                    maxHeight: "90vh", overflowY: "auto",
                                }}>
                                    {all_soft_skills.map((el, id) =>
                                        <div key={id} style={{
                                            display: "flex", flexDirection: "column", height: "fit-content", marginTop: "3.5vh",
                                            width: "100%",
                                        }}>
                                            <div style={{ display: "flex", width: "97%", justifyContent: "space-between", alignItems: "center", marginBottom: "0.5vh" }}>
                                                {decideIcon(el)}
                                                <div style={{ color: text_color, }}>{`${el.skill_name} `}</div>
                                            </div>
                                            <ProgressBar
                                                completed={el.skilled}
                                                bgColor={text_color}
                                                height={"3vh"}
                                                baseBgColor={BG}
                                                customLabel={`${el.skilled}%`}
                                                // borderRadius={"1vh"}
                                                labelSize={"1.8vh"}
                                                labelColor={BG}
                                                animateOnRender={true}
                                                barContainerClassName={`bar_container_p_${theme}${n_mode ? "_n" : "_d"}`}
                                                initCompletedOnAnimation={50}
                                                transitionDuration={"0.8s"}
                                                customLabelStyles={{ fontFamily: font3 }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div >}
        </div >
    )
})

export default Skills
