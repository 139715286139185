import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { theme_combinations, font_combinations, blog_colors_combination } from '../../../global_store/theme_combinations';
import { getDatefromTs } from '../../../Utils/dateRelated/dateUtils';


const BlogCard = memo(function BlogCard(props) {
    const theme = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_theme)
    const n_mode = useSelector((state) => state.AllReducerCombined.themeChangeReducers.night_mode)
    const active_font_comb = useSelector((state) => state.AllReducerCombined.themeChangeReducers.active_font_comb)
    let { bg_day, bg_night, clr1, clr2, clr3, clr4, clr5, clr6, clr7, clr8 } = theme_combinations[theme]
    let { nav_btn_font_style, regular_text_style, font3, intro_name_font_style } = font_combinations[active_font_comb]
    let { BG, is_mobile1, is_mobile3, text_color, active_menu, pages, is_mobile2, is_tablet2, is_tablet1, measure, styles,
        blog_cards, idd, ell, ts_created } = props

    const bg = n_mode ? blog_colors_combination.blog_card_bg_night : blog_colors_combination.blog_card_bg_day
    const txt_clr = n_mode ? blog_colors_combination.blog_text_color_night : blog_colors_combination.blog_text_color_day
    let data = ell


    return (
        <div style={{ width: "100%", marginTop: `${measure * 4}vh` }}>
            {
                !is_mobile1 &&
                <div style={{ width: "100%", height: "25vh", background: bg, borderRadius: "1vh", color: txt_clr, }}> {/* PC */}
                    <div style={{ ...styles.flex_common6, height: "75%", justifyContent: "space-between", padding: "2vh", }}>
                        <div style={{ fontSize: "2vh" }}>{getDatefromTs(ts_created, "DD MMM YYYY")}</div>
                        <div style={{ fontSize: "2vh", fontWeight: "bold" }}>{data?.b_title}</div>
                        <div style={{ fontSize: "1.7vh", fontFamily: font_combinations[`f_comb3`].regular_text_style }}>{data?.b_desc}</div>
                        <div style={{ fontSize: "1.3vh" }}>{data?.b_topix}</div>
                    </div>
                </div>}

            {is_mobile1 &&
                <div style={{ width: "100%", height: "25vh", background: bg, borderRadius: "1vh", color: txt_clr, }}> {/* PC */}
                    <div style={{ ...styles.flex_common6, height: "75%", justifyContent: "space-between", padding: "2vh" }}>
                        <div style={{ fontSize: "2vh" }}>{getDatefromTs(ts_created, "DD MMM YYYY")}</div>
                        <div style={{ fontSize: "2vh", fontWeight: "bold" }}>{data?.b_title}</div>
                        <div style={{ fontSize: "1.8vh", fontFamily: font_combinations[`f_comb3`].regular_text_style }}>{data?.b_desc}</div>
                        <div style={{ fontSize: "1.6vh" }}>{data?.b_topix}</div>
                    </div>
                </div>
            }
        </div>

    )
})

export default BlogCard
